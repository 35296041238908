import FilterIcon from 'images/icon-filter-white.svg'
import Search from 'images/icon-search-white.svg'
import Tick from 'images/tick.svg'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import RootState from 'store/state'

import { Button, Input, Table, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

import Status from '../../components/Status'
import {
    clearAgents,
    getAgents,
    setAgentsFilter,
    showAgents,
} from '../../store/agents/agents.actions'
import TimeInState from './TimeInState'

const cols: SATableColumn[] = [
    {
        value: 'name',
        name: '',
        render: (agent: any) => {
            return (
                <div className="agent-details">
                    <span className="agent-details-name">{agent.name}</span>
                    {agent.beenInStateFor && (
                        <span className="agent-details-time">
                            <TimeInState beenInStateFor={agent.beenInStateFor} />
                        </span>
                    )}
                </div>
            )
        },
    },
    {
        value: 'status',
        name: '',
        position: 'right',
        render: (agent: any) => (
            <Status
                isAvailable={agent.state === 'Available'}
                statusName={agent.inCall ? 'In Call' : agent.state}
            />
        ),
    },
]

interface Props extends ReturnType<typeof mapStateToProps> {
    getAgents: typeof getAgents
    showAgents: typeof showAgents
    clearAgents: typeof clearAgents
    setAgentsFilter: typeof setAgentsFilter
}

class Agents extends PureComponent<Props> {
    private popup!: TriggeredPopup | null
    readonly state = {
        search: '',
        focus: 0,
    }

    private refreshInterval: NodeJS.Timer | undefined
    private input:
        | React.RefObject<HTMLInputElement | HTMLTextAreaElement | null>
        | null
        | undefined = React.createRef()
    private queueList: React.RefObject<HTMLUListElement> | null | undefined = React.createRef()

    tableiseData = () => {
        const { agents, states, agentExpiry } = this.props
        const { search } = this.state
        if (!agents.length) return []
        //Sort the states into Available at the top, offline at the bottom and the rest in the same order
        const sortedStates = states.filter((s) => s.name !== 'Available' && s.name !== 'Offline')
        sortedStates.unshift(states.find((s) => s.name === 'Available')!)
        sortedStates.push(states.find((s) => s.name === 'Offline')!)
        return (
            agents
                //Need to remove agents with no username and any admins (username in the form 'AROAJ6UD76XIE432KDJSC:username')
                .filter((a) => {
                    //If offline check agentExpiry
                    if (agentExpiry && a.state === 'Offline') {
                        const timeOffline = Date.now() - new Date(a.state_start_timestamp).getTime()
                        if (timeOffline > agentExpiry) return false
                    }
                    const { name, username } = a
                    if (!name || (username && username[21] === ':')) return false
                    return search ? name.toLowerCase().includes(search.toLowerCase()) : true
                })
                .map((agent) => ({
                    ID: agent.agent_id,
                    name: {
                        name: agent.name || agent.username.split(':').slice(-1)[0],
                        beenInStateFor: agent.state_start_timestamp,
                    },
                    status: { state: agent.state, inCall: agent.on_call },
                }))
                .sort((a, b) => {
                    //Available at top, then by states order then by name
                    const aIndex = sortedStates.findIndex((s) => s.name === a.status.state)
                    const bIndex = sortedStates.findIndex((s) => s.name === b.status.state)
                    return aIndex === bIndex
                        ? a.name.name.toLowerCase() > b.name.name.toLowerCase()
                            ? 1
                            : -1
                        : aIndex > bIndex
                          ? 1
                          : -1
                })
        )
    }

    componentDidMount() {
        this.props.getAgents()
        this.refreshInterval = setInterval(() => this.onRefresh(), 10000)
        setTimeout(() => {
            this.input?.current?.focus() //Focus on the input after the slide has fully opened.
        }, 500)
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval!)
    }

    componentDidUpdate(prevProps: Props) {
        //If routing profile updates need to reapply filter
        const { routingProfile, agentsFilter } = this.props
        if (prevProps.routingProfile?.ID !== routingProfile?.ID && agentsFilter) {
            this.onFilter()
        }
    }

    onRefresh = () => {
        this.props.clearAgents()
        this.props.getAgents()
    }
    onClearFilter = () => {
        this.props.setAgentsFilter()
        this.props.getAgents()
        this.popup!.closePopup()
    }
    onFilter = () => {
        const { routingProfile } = this.props
        this.props.setAgentsFilter({
            routingProfileIDs: routingProfile?.ID.split('/')[3],
        })
        this.props.getAgents()
        this.popup!.closePopup()
    }

    onSearch = (search: any) => {
        this.setState({ search })
    }

    onFocusChange = () => {
        const child = this.queueList?.current?.children[this.state.focus]
        child?.querySelector('button')?.focus()
    }

    onKeyDown = (evt: React.KeyboardEvent) => {
        const { focus } = this.state
        if (evt.key === 'ArrowUp') {
            if (focus === 0) return
            this.setState({ focus: focus - 1 }, this.onFocusChange)
        }

        if (evt.key === 'ArrowDown') {
            if (focus === this.queueList?.current?.children?.length! - 1) return
            this.setState({ focus: focus + 1 }, this.onFocusChange)
        }
    }

    onCickRetry = () => this.props.getAgents()

    render() {
        const { agentsError, agentsLoading, agents, routingProfile, agentsFilter } = this.props
        return (
            <aside title="Agent Directory" className="agents">
                <header className="agents-header">
                    <div className="row middle">
                        <p>Agent Directory</p>
                    </div>
                </header>
                <div className="agents-search">
                    <Input
                        ref={this.input}
                        name="Agent search"
                        placeholder="Search"
                        onChange={this.onSearch}
                        value={this.state.search}
                    />

                    <div className="agents-filter">
                        <TriggeredPopup
                            ref={(r: any) => (this.popup = r)}
                            noShadow
                            fromRight
                            closeOnClickOutside
                            trigger={
                                <Button className="agents-filter-button">
                                    <span className="agents-filter-button-text">
                                        {!agentsFilter ? 'All' : `${routingProfile?.name}`}
                                    </span>
                                    <img src={FilterIcon} title="Filter" alt="Filter" />
                                </Button>
                            }
                        >
                            <ul className="agents-filter-list" ref={this.queueList}>
                                <li className="row between middle">
                                    <Button
                                        className="filter-option"
                                        onClick={this.onClearFilter}
                                        onKeyDown={this.onKeyDown}
                                    >
                                        <span>All</span>
                                        {!agentsFilter ? (
                                            <img src={Tick} alt="Selected" title="Selected" />
                                        ) : null}
                                    </Button>
                                </li>
                                <li className="row between middle">
                                    <Button
                                        className="filter-option"
                                        onClick={this.onFilter}
                                        onKeyDown={this.onKeyDown}
                                    >
                                        <span>{routingProfile?.name}</span>
                                        {!!agentsFilter ? (
                                            <img src={Tick} alt="Selected" title="Selected" />
                                        ) : null}
                                    </Button>
                                </li>
                            </ul>
                        </TriggeredPopup>
                    </div>
                </div>
                {agentsError ? (
                    <div className="agents-error">
                        There was an error <br />
                        <Button elastic onClick={this.onCickRetry}>
                            <u>Try again</u>
                        </Button>
                    </div>
                ) : agentsLoading && !agents.length ? (
                    <div className="agents-loading">
                        <BeatLoader color="#fff" />
                    </div>
                ) : (
                    <Table
                        caption="Agents directory"
                        cols={cols}
                        data={this.tableiseData()}
                        noData={
                            <div className="no-data-message-container">
                                <img src={Search} alt="Search" title="Search" />
                                <span>No agents have been found</span>
                            </div>
                        }
                    />
                )}
            </aside>
        )
    }
}

function mapStateToProps(state: RootState) {
    return {
        ...state.agents,
        routingProfile: state.user?.routingProfile,
        states: state.user?.states ?? [],
        agentExpiry: state.app.appConfig.agentExpiry,
    }
}

export default connect(mapStateToProps, { getAgents, showAgents, clearAgents, setAgentsFilter })(
    Agents as any,
)
