import './audio-player.scss'

import { Box, Popup } from '@missionlabs/smartagent-app-components'
import useSize from 'hooks/useSize'
import React, { useEffect, useRef, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useSelector } from 'react-redux'
import { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch'
import { selectScreenRecordingURL } from 'store/contactSearch/contactSearch.selectors'
import { withContactConsumerContext } from 'widgets/contexts/contact'
import { Audio } from './Audio'
import { Footer } from './Footer'
import Header from './Header'
import { Seeker } from './Seeker'
import { Track } from './Track'
import VideoPlayer from './VideoPlayerSR'
import './audio-player.scss'
import { generateWidgetTitle } from './helpers/AudioPlayer'
import { AudioPlayerProps } from './interfaces/AudioPlayer'

export const _AudioPlayer: React.FC<AudioPlayerProps> = ({
    signedUrl,
    waveform: _waveform,
    context,
    canDownload,
    onAudioError,
    contactID,
    recordingDate,
    widgetTitle,
    persistentElapsed,
    setPersistentElapsed,
}) => {
    // UI
    const [title, setTitle] = useState<string>('')
    const [waveform] = useState(JSON.parse(_waveform as unknown as string))
    const { channel1, channel2 } = waveform ?? {}
    const { size: trackSize, ref } = useSize<HTMLDivElement>(true)

    // REFS
    const [containerRef, setContainerRef] =
        useState<React.MutableRefObject<HTMLDivElement | null> | null>(null)
    const [videoRef, setVideoRef] =
        useState<React.MutableRefObject<HTMLVideoElement | null> | null>(null)
    const [audioRef, setAudioRef] = useState<React.RefObject<HTMLAudioElement> | null>(null)

    // Refs for audio nodes
    const audioContextRef = useRef<AudioContext | null>(null)
    const sourceRef = useRef<MediaElementAudioSourceNode | null>(null)
    const splitterRef = useRef<ChannelSplitterNode | null>(null)
    const mergerRef = useRef<ChannelMergerNode | null>(null)
    const leftGainRef = useRef<GainNode | null>(null)
    const rightGainRef = useRef<GainNode | null>(null)

    // MEDIA PLAYER STATE
    const [duration, setDuration] = useState(0)
    const [playing, setPlaying] = useState(false)
    const [ch1Muted, setCh1Muted] = useState(false)
    const [ch2Muted, setCh2Muted] = useState(false)
    const [elapsed, setElapsed] = useState(persistentElapsed ?? 0)
    const [seekPosition, setSeekPosition] = useState<number>(0)
    const [manualSeekPosition, setManualSeekPosition] = useState<number | null>(null)
    const [isTimeUpdateActive, setIsTimeUpdateActive] = useState(true)

    // VIDEO PLAYER STATE
    const [videoZoomControls, setVideoZoomControls] = useState<ReactZoomPanPinchHandlers | null>(
        null,
    )
    const [isFullScreen, toggleFullScreen] = useState(false)

    const screenRecordingURL = useSelector(selectScreenRecordingURL)

    /**
     * The function calculates and returns a clamped percentage based on the position of a mouse click
     * within the track element.
     * @param event - The `event` parameter is triggered when a user interacts with a `<div>` element in the UI.
     * @returns The function  returns the clamped percentage value, which is a number between 0 and 100
     * representing the percentage of the click position relative to the width of the track element.
     */
    const calculateClampedPercentage = (event: React.MouseEvent<HTMLDivElement>) => {
        const trackElement = event.currentTarget
        const rect = trackElement.getBoundingClientRect()

        const clickPositionRelative = event.clientX - rect.left
        const newProgressPercentage = (clickPositionRelative / rect.width) * 100
        const clampedPercentage = Math.max(0, Math.min(100, newProgressPercentage))
        return clampedPercentage
    }

    /**
     * The function handles seeking functionality by updating the seek position and elapsed time based on user input.
     * @param event - The `event` parameter represents the mouse event that triggered the seek action.
     * @returns The function `onSeek` is returning the `clampedPercentage` value.
     */
    const onSeek = async (event: React.MouseEvent<HTMLDivElement>) => {
        const clampedPercentage = calculateClampedPercentage(event)
        const newElapsed = (clampedPercentage / 100) * duration

        // update UI, elapsed time state and temporarily disable time updates
        // to avoid conflicts during the seek action
        setManualSeekPosition(clampedPercentage)
        setElapsed(newElapsed)
        setIsTimeUpdateActive(false)

        // update the current playback time for the audio element, if it exists.
        if (audioRef?.current) {
            audioRef.current.currentTime = newElapsed
        }

        // update the current playback time for the video element, if it exists.
        if (videoRef?.current) {
            videoRef.current.currentTime = newElapsed
        }

        // after a short delay, reset the seek position indicator and re-enable time updates
        setTimeout(() => {
            setManualSeekPosition(null)
            setIsTimeUpdateActive(true)
        }, 100)

        return clampedPercentage
    }

    const player = useRef<HTMLDivElement>(null)

    const muteChannel = (channel: number) => {
        if (channel === 1) {
            // LEFT CHANNEL
            setCh1Muted(!ch1Muted)
        } else if (channel === 2) {
            // RIGHT CHANNEL
            setCh2Muted(!ch2Muted)
        }
    }

    // USE EFFECTS

    // initialises the audio context and audio graph once audio is available
    useEffect(() => {
        const initAudioContext = () => {
            if (!audioRef?.current) return
            if (audioContextRef.current) return

            const audioContext = new AudioContext()
            audioContextRef.current = audioContext

            const source = audioContext.createMediaElementSource(audioRef.current)
            sourceRef.current = source

            const splitter = audioContext.createChannelSplitter(2)
            const merger = audioContext.createChannelMerger(2)

            const leftGain = audioContext.createGain()
            const rightGain = audioContext.createGain()

            source.connect(splitter)
            splitter.connect(leftGain, 0)
            splitter.connect(rightGain, 1)
            leftGain.connect(merger, 0, 0)
            rightGain.connect(merger, 0, 1)
            merger.connect(audioContext.destination)

            // update refs for muting/unmuting
            splitterRef.current = splitter
            mergerRef.current = merger
            leftGainRef.current = leftGain
            rightGainRef.current = rightGain

            // set initial mute state for both channels
            leftGain.gain.setValueAtTime(ch1Muted ? 0 : 1, audioContext.currentTime)
            rightGain.gain.setValueAtTime(ch2Muted ? 0 : 1, audioContext.currentTime)
        }

        initAudioContext()

        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close()
            }
        }
    }, [audioRef])

    // updates gain nodes when muting/unmuting
    useEffect(() => {
        if (leftGainRef.current && rightGainRef.current && audioContextRef.current) {
            leftGainRef.current.gain.setValueAtTime(
                ch1Muted ? 0 : 1,
                audioContextRef.current.currentTime,
            )
            rightGainRef.current.gain.setValueAtTime(
                ch2Muted ? 0 : 1,
                audioContextRef.current.currentTime,
            )
        }
    }, [ch1Muted, ch2Muted])

    // setting widget title based on screenRecording
    useEffect(() => {
        const newTitle = generateWidgetTitle(screenRecordingURL, widgetTitle)
        setTitle(newTitle)
    }, [screenRecordingURL])

    // sync video and audio playback
    useEffect(() => {
        if (!audioRef?.current) return

        if (playing) {
            // videoRef is optional
            videoRef?.current?.play()
            audioRef.current.play()
        } else {
            // videoRef is optional
            videoRef?.current?.pause()
            audioRef.current.pause()
        }
    }, [playing])

    // setting duration of audio element in local state once its metadata is loaded
    useEffect(() => {
        const audioElement = audioRef?.current
        if (!audioElement) return

        const handleLoadedMetadata = () => {
            const duration = audioElement?.duration
            if (duration) {
                setDuration(duration)
            }
        }

        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)

        return () => {
            audioElement?.removeEventListener('loadedmetadata', handleLoadedMetadata)
        }
    }, [audioRef])

    // updates the elapsed time of audio playback using requestAnimationFrame for smooth updates.
    // automatically cleans up the animation frame when component unmounts or dependencies change.
    useEffect(() => {
        const audioElement = audioRef?.current
        if (!audioElement || !isTimeUpdateActive) return
        let rafId: number

        const updateTime = () => {
            const currentTime = audioElement.currentTime
            setElapsed(currentTime)
            rafId = requestAnimationFrame(updateTime)
        }

        rafId = requestAnimationFrame(updateTime)

        return () => {
            cancelAnimationFrame(rafId)
        }
    }, [audioRef, isTimeUpdateActive])

    // synchronises the audio player's current time with `persistentElapsed` whenever this changes.
    // this ensures the audio player's state remains consistent when toggling fullscreen mode.
    useEffect(() => {
        if (persistentElapsed == null) return

        if (!isNaN(persistentElapsed) && isFinite(persistentElapsed)) {
            setElapsed(persistentElapsed)
            if (audioRef?.current) {
                audioRef.current.currentTime = persistentElapsed
            }
        }
    }, [persistentElapsed, audioRef?.current])

    // synchronises the seek position and elapsed time based on user input or elapsed playback.
    // - If a manual seek position is provided, calculate the corresponding elapsed time
    // and update both the elapsed time and seek position.
    // - If no manual seek position is provided, calculate the seek position as a percentage
    //   of the elapsed time relative to the duration, ensuring the value stays within [0, 100].
    useEffect(() => {
        if (manualSeekPosition !== null) {
            const newElapsed = (manualSeekPosition / 100) * duration
            setElapsed(newElapsed)
            setSeekPosition(manualSeekPosition)
        } else {
            const newPosition = duration
                ? Math.min(100, Math.max(0, (elapsed / duration) * 100))
                : 0
            setSeekPosition(newPosition)
        }
    }, [manualSeekPosition, elapsed, duration])

    // <Header /> displayed when MediaPlayer section is hidden
    // all controls should be hidden
    const hiddenHeader = (
        <div className="row middle evenly grow">
            <H>{title}</H>
        </div>
    )

    const audioPlayerComponent = (
        <Box
            isFullScreen={isFullScreen}
            className="audio-player"
            alt
            collapse
            boxLabel={title}
            {...(hiddenHeader ? { hiddenHeader } : {})}
            header={
                <Header
                    screenRecordingURL={screenRecordingURL}
                    canDownload={canDownload}
                    contactID={contactID}
                    recordingDate={recordingDate}
                    signedUrl={signedUrl}
                    title={title}
                    fullScreen={{ isFullScreen, toggleFullScreen }}
                    refs={{ audioRef, containerRef, videoRef }}
                    playbackState={{
                        elapsed,
                        setElapsed,
                        playing,
                        setPlaying,
                        duration,
                        setPersistentElapsed,
                    }}
                    videoZoomControls={videoZoomControls}
                />
            }
        >
            {/* VIDEO PLAYER */}
            {screenRecordingURL && (
                <div className="sa-videoplayer screen-recording-video" ref={player}>
                    <VideoPlayer
                        isPlaying={playing}
                        seekPosition={elapsed}
                        setContainerRef={setContainerRef}
                        url={screenRecordingURL}
                        setVideoRef={setVideoRef}
                        setVideoZoomControls={setVideoZoomControls}
                    />
                </div>
            )}

            {/* AUDIO PLAYER */}
            <div ref={ref} data-testid="audio-player" className="sa-audioplayer">
                <Seeker to={seekPosition} onSeek={onSeek} />

                <Track
                    channel={channel1}
                    currentTimePercentage={seekPosition}
                    currentTimeSeconds={elapsed}
                    maxTimeSeconds={duration}
                    id={1}
                    muted={ch1Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                    width={trackSize.width}
                    isPlaying={playing}
                />

                <Track
                    channel={channel2}
                    currentTimePercentage={seekPosition}
                    currentTimeSeconds={elapsed}
                    maxTimeSeconds={duration}
                    id={2}
                    muted={ch2Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                    width={trackSize.width}
                    isPlaying={playing}
                />
                <Footer time={duration} />
            </div>

            <Audio
                time={duration}
                url={signedUrl}
                context={context}
                stateFns={{ setCh1Muted, setCh2Muted, setPlaying, setElapsed }}
                onError={onAudioError}
                setAudioRef={setAudioRef}
            />
        </Box>
    )

    return isFullScreen ? (
        <Popup id="sa-audioplayer-popup" center>
            {audioPlayerComponent}
        </Popup>
    ) : (
        audioPlayerComponent
    )
}

export const AudioPlayer = withContactConsumerContext(_AudioPlayer)
