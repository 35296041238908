var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { PureComponent } from "react";
import Button from "./Button.mjs";
import { g as getObjectSubset } from "./index-Cjs3E3kR.mjs";
const CollapseButton = ({
  boxLabel = "",
  collapse,
  hidden,
  onToggle,
  downArrow: downArrow2,
  downArrowLight: downArrowLight2,
  alt
}) => {
  return collapse && /* @__PURE__ */ jsx(
    Button,
    {
      "aria-expanded": !hidden,
      type: "button",
      onClick: () => onToggle(),
      "aria-label": `${boxLabel} Widget`,
      className: classNames({
        "box-header-button": true,
        up: !hidden,
        down: hidden,
        hidden
      }),
      children: /* @__PURE__ */ jsx(
        "img",
        {
          "data-testid": "collapse-button-img",
          className: `collapse-arrow ${hidden ? "down" : "up"}`,
          src: alt ? downArrow2 : downArrowLight2,
          alt: `Collapse ${boxLabel || ""}`,
          title: `Collapse ${boxLabel || ""}`,
          "aria-hidden": true
        }
      )
    }
  );
};
const downArrow = "data:image/svg+xml,%3csvg%20width='24'%20height='24'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath%20d='M7.557%209.354l4.417%204.416%204.483-4.329a.913.913%200%20111.269%201.314l-5.076%204.9a.918.918%200%2001-.786.249.912.912%200%2001-.61-.27l-4.988-4.99a.913.913%200%20111.29-1.29z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%23556272'%20fill-rule='nonzero'%20xlink:href='%23a'/%3e%3c/svg%3e";
const downArrowLight = "data:image/svg+xml,%3csvg%20width='24'%20height='24'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath%20d='M7.557%209.354l4.417%204.416%204.483-4.329a.913.913%200%20111.269%201.314l-5.076%204.9a.918.918%200%2001-.786.249.912.912%200%2001-.61-.27l-4.988-4.99a.913.913%200%20111.29-1.29z'%20id='a'/%3e%3c/defs%3e%3cuse%20fill='%23fff'%20fill-rule='nonzero'%20xlink:href='%23a'/%3e%3c/svg%3e";
class Box extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      hidden: this.props.hidden || false,
      showMore: false
    });
    __publicField(this, "getIsHidden", () => {
      const { controlledHidden } = this.props;
      if (controlledHidden !== void 0)
        return controlledHidden;
      return this.state.hidden;
    });
    __publicField(this, "onToggle", () => {
      const { onToggle } = this.props;
      this.setState({ hidden: !this.state.hidden });
      onToggle == null ? void 0 : onToggle(!this.getIsHidden());
    });
    __publicField(this, "renderChildren", () => {
      const { lazy, children } = this.props;
      if (this.state.hidden && lazy)
        return null;
      return children;
    });
  }
  componentDidUpdate(prevProps) {
    const { hidden } = this.props;
    if (prevProps.hidden !== hidden) {
      this.setState({
        hidden
      });
    }
  }
  render() {
    const {
      className,
      alt,
      collapse,
      boxLabel,
      more,
      toggleOnHeaderClick,
      header,
      hiddenHeader,
      isFullScreen
    } = this.props;
    const { showMore, hidden: hiddenState } = this.state;
    const ariaAttributes = getObjectSubset(this.props, "aria");
    const headerAttributes = {};
    if (collapse && toggleOnHeaderClick) {
      headerAttributes["onClick"] = this.onToggle;
      headerAttributes["role"] = "button";
      headerAttributes["tabIndex"] = 0;
    }
    const hidden = this.getIsHidden();
    return /* @__PURE__ */ jsxs(
      "section",
      {
        ...ariaAttributes,
        "aria-label": boxLabel,
        className: classNames([
          className,
          "box",
          "sa-box",
          alt ? "alt" : null,
          hidden ? "box-collapse sa-box-collapse" : null
        ]),
        children: [
          header ? /* @__PURE__ */ jsxs(
            "div",
            {
              className: classNames([
                "header",
                "sa-box-header",
                toggleOnHeaderClick ? "toggle-on-header-click" : null
              ]),
              ...headerAttributes,
              children: [
                hiddenState && hiddenHeader ? hiddenHeader : header,
                !isFullScreen ? /* @__PURE__ */ jsx(
                  CollapseButton,
                  {
                    collapse,
                    boxLabel,
                    hidden,
                    onToggle: this.onToggle,
                    downArrow,
                    downArrowLight,
                    alt
                  }
                ) : null
              ]
            }
          ) : null,
          /* @__PURE__ */ jsxs(
            "div",
            {
              "data-testid": "box-body",
              className: classNames({
                "box-body": true,
                "sa-box-body": true,
                hidden
              }),
              children: [
                this.renderChildren(),
                showMore && more,
                more && /* @__PURE__ */ jsx("div", { className: "sa-box-show-more", "aria-label": "show more/less", children: /* @__PURE__ */ jsx(
                  Button,
                  {
                    type: "button",
                    onClick: () => this.setState({ showMore: !showMore }),
                    "aria-expanded": showMore ? "true" : "false",
                    "aria-controls": "content",
                    children: /* @__PURE__ */ jsxs("span", { children: [
                      "Show ",
                      showMore ? "Less" : "More",
                      /* @__PURE__ */ jsx(
                        "img",
                        {
                          src: downArrow,
                          className: classNames({ "rotate-180": showMore }),
                          alt: ""
                        }
                      )
                    ] })
                  }
                ) })
              ]
            }
          )
        ]
      }
    );
  }
}
__publicField(Box, "defaultProps", {
  header: void 0,
  hiddenHeader: void 0,
  className: void 0,
  alt: false,
  collapse: false,
  hidden: false,
  lazy: false,
  toggleOnHeaderClick: false
});
export {
  Box as default
};
