import React, { useEffect, useRef } from 'react'
import {
    ReactZoomPanPinchHandlers,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch'
import './video-player.scss'

interface VideoPlayerSRProps {
    isPlaying: boolean
    seekPosition: number
    setContainerRef: React.Dispatch<
        React.SetStateAction<React.MutableRefObject<HTMLDivElement | null> | null>
    >
    setVideoRef: React.Dispatch<
        React.SetStateAction<React.MutableRefObject<HTMLVideoElement | null> | null>
    >
    url: string
    setVideoZoomControls: React.Dispatch<React.SetStateAction<ReactZoomPanPinchHandlers | null>>
}

const VideoPlayerSR = ({
    isPlaying,
    seekPosition,
    setContainerRef,
    setVideoRef,
    url,
    setVideoZoomControls,
}: VideoPlayerSRProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)

    useEffect(() => {
        togglePlayPause()
    }, [isPlaying])

    useEffect(() => {
        setContainerRef(containerRef)
        setVideoRef(videoRef)
    }, [])

    // Play or Pause Video
    const togglePlayPause = () => {
        const video = videoRef.current
        if (video) {
            if (!isPlaying) {
                video.pause()
            } else {
                video.play()
            }
        }
    }

    // Handle seek position changes
    useEffect(() => {
        const video = videoRef.current
        if (video && typeof seekPosition === 'number') {
            video.currentTime = seekPosition
        }
    }, [seekPosition])

    return (
        <div className="video-container" ref={containerRef}>
            <TransformWrapper
                onInit={setVideoZoomControls}
                initialScale={1}
                minScale={0}
                centerOnInit={true}
            >
                <TransformComponent
                    wrapperStyle={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    contentStyle={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <video
                        data-testid="video-player"
                        muted
                        ref={videoRef}
                        width="600"
                        controls={false}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            maxWidth: '100%',
                        }}
                    >
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
}

export default VideoPlayerSR
