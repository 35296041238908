import { useDispatch } from 'react-redux'

import MissedContactCard from 'components/MissedContactCard'

import { ReactComponent as PhoneIcon } from 'images/phone.svg'

import { clearCall } from 'store/call/call.actions'

interface Props {
    currentStatus: connect.AgentAvailStates | connect.AgentErrorStates
}

const MissedCall = ({ currentStatus }: Props) => {
    const dispatch = useDispatch()

    const onCloseVoice = () => {
        dispatch(clearCall())
    }

    const getCallState = (callStatus: connect.AgentAvailStates | connect.AgentErrorStates) => {
        if (callStatus === connect.AgentErrorStates.MISSED_CALL_AGENT) {
            return 'Missed'
        }

        if (callStatus === connect.AgentErrorStates.FAILED_CONNECT_AGENT) {
            return 'Rejected'
        }
    }

    const callState = getCallState(currentStatus)

    if (!callState) return null

    return (
        <MissedContactCard
            onClose={onCloseVoice}
            ariaLabel={`Voice contact ${callState}`}
            icon={<PhoneIcon title='Phone' data-testid='call-icon' aria-hidden={true} />}
            header={`${callState} Call`}
        />
    )
}

export default MissedCall
