import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

import ContactName from './ContactName'

import styles from './ContactItem.module.scss'

interface Props extends PropsWithChildren {
    selected?: boolean
    onSelect: () => void
    disabled?: boolean
    acw?: boolean
    mainContentClassName?: string
    className?: string
    ariaLabel?: string
    contactName: string
    description?: string
    descriptionMobileHidden?: boolean
    icon: JSX.Element
}

const ContactItem: React.FC<Props> = ({
    onSelect,
    selected,
    disabled,
    acw,
    contactName,
    description,
    icon,
    children,
    className = '',
    mainContentClassName = '',
    ariaLabel = '',
    descriptionMobileHidden = false,
}) => {
    const elemForScreenReaderRef = useRef<HTMLSpanElement>(null)
    const btnRef = useRef<HTMLButtonElement>(null)

    const buttonClasses = classNames(className, {
        [styles.contactItem]: true,
        [styles.selected]: selected,
        [styles.acw]: acw,
        [styles.disableClick]: disabled,
    })

    const mainContentClasses = classNames(mainContentClassName, styles.wrapper)

    const descriptionClasses = classNames(styles.description, {
        [styles.descriptionMobileHidden]: descriptionMobileHidden,
    })

    const onSelectItem = () => {
        if (disabled) return
        onSelect()
    }

    useEffect(() => {
        if (elemForScreenReaderRef?.current) {
            elemForScreenReaderRef.current.innerText = ariaLabel
        }
    }, [ariaLabel])

    useEffect(() => {
        if (btnRef.current) {
            btnRef.current.focus()
        }
    }, [])

    return (
        <Button
            innerRef={btnRef}
            disabled={disabled}
            className={buttonClasses}
            onClick={onSelectItem}
        >
            <span ref={elemForScreenReaderRef} className="only-visible-to-screen-readers">
                {ariaLabel}
            </span>

            {icon}

            <div className={mainContentClasses}>
                <div className={styles.contactName}>
                    <ContactName name={contactName} />
                </div>

                <p title={description} className={descriptionClasses}>
                    {description}
                </p>
            </div>

            <div className={styles.additionalContent}>{children}</div>
        </Button>
    )
}

export default ContactItem
