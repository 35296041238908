import type { ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as AgentAPI from 'services/api/api.agent'
import * as AuditLogAPI from 'services/api/api.auditLog'
import * as AuthAPI from 'services/api/api.auth'
import * as CallbackQueuesAPI from 'services/api/api.callbackQueues'
import * as CompanyAPI from 'services/api/api.company'
import * as ConnectAPI from 'services/api/api.connect'
import * as EmailAPI from 'services/api/api.email'
import * as LinesAPI from 'services/api/api.line'
import * as QueueAPI from 'services/api/api.queue'
import * as ReportsAPI from 'services/api/api.report'
import * as WebformAPI from 'services/api/api.webform'
import { AppFeatures } from 'store/app/app.features'
import { createNotification } from 'store/notification/notification.actions'
import { NotificationAction } from 'store/notification/notification.reducer'
import RootState from 'store/state'
import { logout } from 'store/user/user.actions'
import { LogoutAction, UserAction } from 'store/user/user.reducer'
import { canUserUseFeature } from 'utils'
import { AuditLogsQueryParams } from 'views/AdminSettings/AuditLogs/types'
import { UpdateCallbackQueueBody } from 'views/Settings/Queues/types'

import * as SettingsActions from './settings.reducer'
import { IAgentCreate, IPermission, IRoutingProfile, PatchConnect, SmartAgentUser } from './settings.state'
import { isAnEmail } from './settings.utils'

import type { IAgent } from '@missionlabs/smartagent-lib-shared/build/agent'
import type { ISettings } from '@missionlabs/smartagent-lib-shared/build/settings'

export const {
    setRingingDevice,
    addingUser: setAddingUser,
    enableWebHID: setUserHasEnabledWebHID,
    disableWebHID: setUserHasDisabledWebHID,
    deletingUser,
    disablingUser,
    enablingUser,
} = SettingsActions

export function patchGlobalLine(data: PatchConnect) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | NotificationAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.getGlobalLineLoading(true))

        try {
            const response = await LinesAPI.updateConnectGlobalLine(companyID, token, data)

            dispatch(SettingsActions.updateGlobalLine(response))

            dispatch(
                createNotification({
                    header: 'Success!',
                    text: 'All changes were successfully saved.',
                    type: 'success',
                }),
            )
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error updating lines')
        } finally {
            dispatch(SettingsActions.getGlobalLineLoading(false))
        }
    }
}

export function getGlobalLine() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.getGlobalLineLoading(true))

        try {
            const response = await LinesAPI.getConnectGlobalLine(companyID, token)
            return dispatch(SettingsActions.getGlobalLine(response))
        } catch (err) {
            console.log('Error getting the global line', err)
            dispatch(SettingsActions.getGlobalLineError())
        } finally {
            dispatch(SettingsActions.getGlobalLineLoading(false))
        }
    }
}

export function getLines() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.getLinesLoading(true))

        try {
            const response = await LinesAPI.getConnectLines(companyID, token)

            return dispatch(SettingsActions.getLines(response))
        } catch (err) {
            console.log('Error getting lines', err)
        } finally {
            dispatch(SettingsActions.getLinesLoading(false))
        }
    }
}

export function getUsers() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!
        const reducedPayload = getState().app.appConfig?.reduceGetUserPayload
        const fields = reducedPayload
            ? {
                  fields: 'ID,username,firstName,lastName,email,routingProfileID,hierarchyGroupID,disabled',
              }
            : undefined

        dispatch(SettingsActions.getUsersLoading(true))

        try {
            const [bareUsers, routingProfiles, securityGroups, userHierarchy] = await Promise.all([
                AgentAPI.getUsers(companyID, instanceID, token, fields),

                AgentAPI.getRoutingProfiles(companyID, instanceID, token),

                AgentAPI.getSecurityGroups(companyID, instanceID, token),

                AgentAPI.getUserHierarchy(companyID, instanceID, token).catch(),
            ])

            const users: Array<SmartAgentUser> = bareUsers.map((user: IAgent) => ({
                ...user,
                routingProfile: routingProfiles.find(
                    (rp: IRoutingProfile) => rp.Id === user.routingProfileID,
                )?.Name,
            }))

            dispatch(SettingsActions.getRoutingProfiles(routingProfiles))
            dispatch(SettingsActions.getSecurityGroups(securityGroups))
            dispatch(SettingsActions.getUserHierarchy(userHierarchy ?? {}))
            dispatch(SettingsActions.getUsers(users))
        } catch (err) {
            console.log('err :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.getUsersError(true))
        }
    }
}

export function addUser(agent: IAgentCreate) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const { ID, identityManagement } = getState().app
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!
        const { routingProfiles, cognitoConfig } = getState().settings
        if (identityManagement && identityManagement !== 'connect') {
            delete agent.password
        }
        if (agent.hierarchyGroupID === '') {
            delete agent.hierarchyGroupID
        }

        switch (identityManagement) {
            case 'cognito':
                // If email is a username attribute and the user set their username to an email,
                // then we set the agents email to be their username. This avoids the user having to input an email twice
                if (
                    !cognitoConfig?.usernameAttributes?.find((u) => u === 'email') &&
                    isAnEmail(agent.username) &&
                    !agent.email
                )
                    agent.email = agent.username
                break
        }

        dispatch(SettingsActions.addingUserLoading(true))

        try {
            let newUser = await AgentAPI.postUser(ID, instanceID, token, agent)

            console.log('created user ', newUser)

            newUser = {
                ...newUser,
                routingProfile: routingProfiles?.find(
                    (rp: IRoutingProfile) => rp.Id === agent.routingProfileID,
                )!.Name,
            } as SmartAgentUser

            dispatch(SettingsActions.addUser(newUser as SmartAgentUser))
        } catch (err) {
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.addUserError(e.response?.data.error))
        }
    }
}

// For the function which actually makes the put req to edit the user, see editUser()
// This is just for loading in an agent to edit
export function editingUser(agent?: SmartAgentUser | null) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        if (!agent) {
            return dispatch(SettingsActions.editingUser(null))
        }

        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!

        try {
            dispatch(SettingsActions.editUserRefresh(true))

            const routingProfile = getState().settings.routingProfiles?.find(
                (rp: IRoutingProfile) => rp.Id === agent.routingProfileID,
            )!.Name

            const response = await AgentAPI.getSingleUser(companyID, instanceID, token, agent.ID)

            if (routingProfile) {
                dispatch(
                    SettingsActions.editingUser({
                        ...response,
                        routingProfile,
                    }),
                )
            }
        } catch (err) {
            console.log('error refreshing user', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            if (e?.response?.status === 404) {
                dispatch(SettingsActions.editUserError('User not found'))
            } else {
                dispatch(SettingsActions.editUserError('Something went wrong'))
            }
        }
    }
}

export function editUser(agent: Partial<IAgentCreate>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!
        const routingProfiles = getState().settings.routingProfiles

        try {
            dispatch(SettingsActions.editingUserLoading(true))

            let changedUser = await AgentAPI.putUser(companyID, instanceID, token, agent)

            console.log('AgentAPI putUser success', changedUser)

            changedUser = {
                ...changedUser,
                routingProfile: routingProfiles?.find(
                    (rp: IRoutingProfile) => rp.Id === agent.routingProfileID,
                )!.Name,
            } as SmartAgentUser

            dispatch(SettingsActions.editUser(changedUser as SmartAgentUser))
        } catch (err) {
            console.error('error putting user', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.editUserError(e.response?.data.error))
        }
    }
}

export function deleteUser(agent: SmartAgentUser) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.deleteUserLoading(true))

        try {
            await AgentAPI.deleteUser(companyID, instanceID, agent.ID, token)

            console.log(`deleted agent`, agent)

            dispatch(SettingsActions.deleteUser(agent))
        } catch (err) {
            console.log("couldn't delete agent", err, agent)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.deleteUserError(e.response?.data.error))
        }
    }
}

export function enableUser(agent: SmartAgentUser) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!

        try {
            dispatch(SettingsActions.enableUserLoading(true))

            await AgentAPI.putUser(companyID, instanceID, token, {
                ID: agent.ID,
                disabled: false,
            })

            dispatch(SettingsActions.enableUser(agent))

            dispatch(SettingsActions.enableUserLoading(false))
        } catch (err) {
            console.log('error enabling user', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.enableUserError(e.response?.data.error))
        }
    }
}

export function disableUser(agent: SmartAgentUser) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!

        try {
            dispatch(SettingsActions.disableUserLoading(true))

            await AgentAPI.putUser(companyID, instanceID, token, {
                ID: agent.ID,
                disabled: true,
            })

            dispatch(SettingsActions.disableUser(agent))

            dispatch(SettingsActions.disableUserLoading(false))
        } catch (err) {
            console.log('error disabling user', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.disableUserError(e.response?.data.error))
        }
    }
}

export function getReports() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.getReportsLoading(true))

        try {
            const response = await ReportsAPI.getReports(companyID, token)

            dispatch(SettingsActions.getReports(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'Unable to get reports')
        } finally {
            dispatch(SettingsActions.getReportsLoading(false))
        }
    }
}

export function generateReport(
    reportID: string,
    startDate: string,
    endDate: string,
    email: string,
) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.generateReportsLoading(true))

        try {
            const response = await ReportsAPI.generateReport(
                companyID,
                token,
                reportID,
                startDate,
                endDate,
                email,
            )

            return response.data
        } catch (err) {
            return settingsAPIError(err, dispatch, 'Error generating report')
        } finally {
            dispatch(SettingsActions.generateReportsLoading(false))
        }
    }
}

export function patchLines(lineID: string, data: PatchConnect) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | NotificationAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.updateLinesLoading(true))

        try {
            const response = await LinesAPI.updateConnectLines(companyID, token, lineID, data)

            dispatch(SettingsActions.updateLines(response))

            dispatch(
                createNotification({
                    header: 'Success!',
                    text: 'All changes were successfully saved.',
                    type: 'success',
                }),
            )
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error updating lines')
        } finally {
            dispatch(SettingsActions.updateLinesLoading(false))
        }
    }
}

export function getHierarchyGroups() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AgentAPI.getHierarchyGroups(ID, instance!.ID, token)

            dispatch(SettingsActions.getHierarchyGroups(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting hierarchy groups')
        }
    }
}

export function getRoutingProfiles() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AgentAPI.getRoutingProfiles(ID, instance!.ID, token)

            dispatch(SettingsActions.getRoutingProfiles(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting routing profiles')
        }
    }
}

export function getSecurityGroups() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AgentAPI.getSecurityGroups(ID, instance!.ID, token)

            dispatch(SettingsActions.getSecurityGroups(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting routing profiles')
        }
    }
}

export function getPermissions() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AuthAPI.getPermissions(ID, token)

            const { rolePermissions } = response

            if (!Array.isArray(rolePermissions)) {
                return dispatch(SettingsActions.getPermissionsError(true))
            }

            dispatch(SettingsActions.getPermissions(rolePermissions))
        } catch (err) {
            dispatch(SettingsActions.getPermissionsError(true))

            return settingsAPIError(err, dispatch, 'error getting routing profiles')
        }
    }
}

export function savePermissions(rolePermissions: IPermission[]) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AuthAPI.putPermissions(ID, token, {
                ID,
                rolePermissions,
            })

            dispatch(SettingsActions.getPermissions(rolePermissions))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting routing profiles')
        }
    }
}

export function getQueues() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await QueueAPI.getQueues(ID, instance!.ID, token)

            dispatch(SettingsActions.getQueues(response))
        } catch (err) {
            console.error(`Error getting queues: ${JSON.stringify(err)}`)
        }
    }
}

export function getEnabledQueues(userTags: Record<string, string>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await QueueAPI.getEnabledQueues(ID, instance!.ID, userTags, token)
            const mappedData = response
                .filter((queue) => queue.name?.[0] !== '_')
                .map((queue) => ({
                    Name: queue.name,
                    Arn: queue.resourceId,
                    Id: queue.id,
                    QueueType: queue.queueType,
                }))

            dispatch(SettingsActions.getEnabledQueues(mappedData))
        } catch (err) {
            console.error(`Error getting queues: ${JSON.stringify(err)}`)
        }
    }
}

export function getRTMQueues(userTags: Record<string, string>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction>,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const hasFeature = canUserUseFeature(app.features, auth.features ?? [])

        if (hasFeature(AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED)) {
            return dispatch(getEnabledQueues(userTags))
        } else {
            return dispatch(getQueues())
        }
    }
}

export function getEmailTemplates() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
    ) => {
        try {
            const response = await EmailAPI.getEmailTemplates()

            dispatch(SettingsActions.getEmailTemplates(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting emailTemplates')
        }
    }
}

export function getAllowedAttachmentTypes() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
    ) => {
        try {
            const response = await WebformAPI.getFileTypes()

            dispatch(SettingsActions.getAllowedAttachmentTypes(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting allowedAttachmentTypes')
        }
    }
}

// Note: The getContactFlows from the QueueAPI will eventually be superceded
// by getConnectContactFlows from the ConnectAPI.
//
// Both methods will use different redux state for now so that customers
// will not need the updated ConnectAPI for existing functionality.
// - getContactFlows: settings.contactFlows
// - getConnectContactFlows: settings.connectContactFlows
export function getContactFlows() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await QueueAPI.getContactFlows(ID, instance!.ID, token)

            dispatch(SettingsActions.getContactFlows(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting contact flows')
        }
    }
}

export function getConnectContactFlows() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
    ) => {
        try {
            const response = await ConnectAPI.getContactFlows()

            dispatch(SettingsActions.getConnectContactFlows(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting contact flows')
        }
    }
}

export function getUserHierarchy() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app
        const token = getState().auth.token!

        try {
            const response = await AgentAPI.getUserHierarchy(ID, instance!.ID, token)

            dispatch(SettingsActions.getUserHierarchy(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting user hierarchy')
        }
    }
}

export function getFeatureList() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
    ) => {
        try {
            const response = await AuthAPI.getFeatureList()

            dispatch(SettingsActions.getFeatureList(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting feature list')
        }
    }
}

export function getAuthProviderConfig() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { ID, instance } = getState().app

        try {
            const response = await AuthAPI.getAuthProviderConfig(ID, instance!.ID)

            switch (response.identityManagement) {
                case 'cognito':
                    dispatch(SettingsActions.getCognitoConfig(response ?? {}))
                    break
            }
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting auth provider config')
        }
    }
}

export function getSettings() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(SettingsActions.getSettingsLoading(true))

        try {
            const response = await CompanyAPI.getSettings(companyID, token)

            dispatch(SettingsActions.getSettings(response))
        } catch (err) {
            return settingsAPIError(err, dispatch, 'error getting settings')
        }
    }
}

export function editSettings(settings: Partial<ISettings>) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | NotificationAction
        >,
        getState: () => RootState,
    ) => {
        dispatch(SettingsActions.editSettingsLoading(true))

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const response = await CompanyAPI.putSettings(companyID, token, settings)

            dispatch(SettingsActions.editSettings(response.data))

            dispatch(
                createNotification({
                    header: 'Success!',
                    text: 'All changes were successfully saved.',
                    type: 'success',
                }),
            )
        } catch (error) {
            dispatch(SettingsActions.editSettingsLoading(false))

            console.log(error)
        }
    }
}

const settingsAPIError = (
    err: any,
    dispatch: ThunkDispatch<RootState, undefined, SettingsActions.SettingsAction | UserAction>,
    msg?: string,
) => {
    console.error(msg, err)
    const e = err as AxiosError

    if (e.response?.status === 403) {
        return dispatch(logout())
    }
}

export function getAuditLogs(auditLogsQueryParams: AuditLogsQueryParams) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const companyID = app.ID
        const token = auth.token!

        dispatch(SettingsActions.getAuditLogsLoading(true))
        try {
            const auditLogs = await AuditLogAPI.getAuditLogs(companyID, token, auditLogsQueryParams)

            dispatch(SettingsActions.getAuditLogs(auditLogs))
        } catch (err) {
            console.error('Error getting audit logs :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.getAuditLogsError(true))
        }
    }
}

export function getSettingNamesForAuditLogs() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const companyID = app.ID
        const token = auth.token!

        dispatch(SettingsActions.getSettingNamesForAuditLogsLoading(true))
        try {
            const settingNames = await AuditLogAPI.getSettingNames(companyID, token)

            dispatch(SettingsActions.getSettingNamesForAuditLogs(settingNames))
        } catch (err) {
            console.error('Error getting setting names :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.getSettingNamesForAuditLogsError(true))
        }
    }
}

export function getUsersListForAuditLogs() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const companyID = app.ID
        const token = auth.token!

        dispatch(SettingsActions.getUsersListForAuditLogsLoading(true))
        try {
            const usersList = await AuditLogAPI.getUsersList(companyID, token)

            dispatch(SettingsActions.getUsersListForAuditLogs(usersList))
        } catch (err) {
            console.error('Error getting user list :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.getUsersListForAuditLogsError(true))
        }
    }
}

export function getCallbackQueues() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const instanceID = app.instance!.ID
        const token = auth.token!

        dispatch(SettingsActions.getCallbackQueuesLoading(true))
        try {
            const callBackQueues = await CallbackQueuesAPI.getCallBackQueues(
                token,
                companyID,
                instanceID,
            )

            dispatch(SettingsActions.getCallbackQueues(callBackQueues))
        } catch (err) {
            console.error('Error getting callBack queues :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.getCallbackQueuesError(true))
        }
    }
}

export function updateCallbackQueue(
    updateCallbackQueueBody: UpdateCallbackQueueBody,
    queueID: string,
) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            SettingsActions.SettingsAction | NotificationAction | LogoutAction
        >,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const instanceID = app.instance!.ID
        const token = auth.token!

        dispatch(SettingsActions.updateCallbackQueueLoading(true))
        try {
            const callBackQueue = await CallbackQueuesAPI.updateCallBackQueue(
                token,
                companyID,
                instanceID,
                queueID,
                updateCallbackQueueBody,
            )
            dispatch(SettingsActions.updateCallbackQueue(callBackQueue))

            dispatch(
                createNotification({
                    type: 'success',
                    header: 'Success',
                    text: 'All your changes have been successfully saved',
                }),
            )
        } catch (err) {
            console.error('Error getting callBack queues :', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(SettingsActions.updateCallbackQueueError(true))

            dispatch(
                createNotification({
                    type: 'error',
                    header: 'Error',
                    text: 'Oops something went wrong, please try again later',
                }),
            )
        }
    }
}
