import { ContactItem } from 'components'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CallState from 'store/call/call.state'

import useCall from 'hooks/redux/useCall'
import { ReactComponent as PhoneFilledIcon } from 'images/phone-icon-filled.svg'
import { ReactComponent as PhoneIcon } from 'images/phone.svg'
import { selectContactSubType } from 'store/contact/contact.selectors'
import { setRedirect } from 'store/global/global.actions'
import { capitaliseStr, formatPhoneNumber } from 'utils'
import { CTR } from 'store/contact/contact.state'

const allowStates = [
    connect.ConnectionStateType.CONNECTED,
    connect.ConnectionStateType.BARGE,
    connect.ConnectionStateType.HOLD,
] as string[]

export type Call = CallState & { acw?: boolean, initiationMethod?: CTR['initiationMethod'] }

interface Props {
    call: Call
    selected: boolean
}

const CallListItem: React.FC<Props> = ({ call, selected }) => {
    const dispatch = useDispatch()
    const { connections } = useCall() || {}

    const onSelectCall = () => {
        dispatch(setRedirect('/'))
    }

    const number = useMemo(() => formatPhoneNumber(call.number), [call.number])
    const participantsCount = useMemo(() => {
        const connectionsActive = connections?.filter((conn) =>
            allowStates.includes(conn.status.toLowerCase()),
        )

        return connectionsActive?.length ? connectionsActive.length + 1 : 0
    }, [connections])

    const getSubChannel = (call: Call, contactSubType: string | null | undefined) => {
        if (contactSubType === 'connect:WebRTC') {
            return 'Web Call'
        }

        if (call?.initiationMethod) {
            return `${capitaliseStr(call?.initiationMethod)} Call`;
        }
    }

    const contactSubType = useSelector(selectContactSubType)

    const subChannel = getSubChannel(call, contactSubType)

    const showParticipantsCount = participantsCount > 2

    return (
        <ContactItem
            onSelect={onSelectCall}
            acw={call.acw}
            selected={selected}
            disabled={false}
            ariaLabel={`Contact card voice from`}
            contactName={number}
            description={showParticipantsCount ? `${participantsCount} people` : subChannel}
            descriptionMobileHidden={!showParticipantsCount}
            icon={
                selected ? (
                    <PhoneFilledIcon
                        title="Phone"
                        data-testid="phone-icon-filled"
                        aria-hidden={true}
                    />
                ) : (
                    <PhoneIcon title="Phone" data-testid="phone-icon" aria-hidden={true} />
                )
            }
        >
            <span aria-live="polite" className="only-visible-to-screen-readers">
                {participantsCount} people on the call
            </span>
        </ContactItem>
    )
}

export default CallListItem
