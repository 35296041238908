import './home.scss'

import { Channel } from '@aws-sdk/client-connect'
import { AlertBanner, Button, Popup } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import RecordingControl from 'components/RecordingControl'
import useCall from 'hooks/redux/useCall'
import useCallV2 from 'hooks/redux/useCallV2'
import useContact from 'hooks/redux/useContact'
import useMPC from 'hooks/redux/useMPC'
import useMonitoring from 'hooks/redux/useMonitoring'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useHasMic from 'hooks/useHasMic'
import muted from 'images/button-sound-off.svg'
import React, { useEffect, useState } from 'react'
import { Level } from 'react-accessible-headings'
import { batch, useDispatch, useSelector } from 'react-redux'
import { completeCallACW, setSelectedCall } from 'store/call/call.actions'
import {
    selectAreYouBeingBarged,
    selectConnectedConnections,
    selectYouConnection,
} from 'store/callV2/callV2.reducer'
import { clearContact } from 'store/contact/contact.actions'
import RootState from 'store/state'
import { setState } from 'store/user/user.actions'
import { ContactPanel } from '../ContactPanel'
import AfterCallWork from './AfterCallWork'
import CallHistory from './CallHistory'
import ContactLog from './ContactLog'
import CustomerInputs from './CustomerInputs'
import DPA from './DPA'
import Dialler from './Dialler'
import Monitoring from './Monitoring'
import MultiPartyCalling from './MultiPartyCalling'
import ReportIssue from './ReportIssue'

interface IHomeContext {
    keyPadOpened?: boolean
    setKeyPadOpened?: React.Dispatch<React.SetStateAction<boolean>>
    quickConnectOpened?: boolean
    setQuickConnectOpened?: React.Dispatch<React.SetStateAction<boolean>>
    isQueueListShown?: boolean
    setIsQueueListShown?: React.Dispatch<React.SetStateAction<boolean>>
}

export const HomeContext = React.createContext<IHomeContext>({})

const Home: React.FC = () => {
    const dispatch = useDispatch()

    const hasFeature = useHasFeature()

    const contact = useContact()

    const { hasMic, clearFails } = useHasMic()

    const { isMonitoringOrBarging, isEnhancedMonitoringEnabled, isMonitoring } = useMonitoring()

    const call = useCall()
    const connections = call?.connections ?? []
    const { connections: connectionsV2 } = useCallV2()
    const { isMPC } = useMPC()
    const areYouBeingBarged: boolean = !!useSelector(selectAreYouBeingBarged)

    const lengthOfV2Connections = useSelector(selectConnectedConnections).length

    const hasTwoParticipants = lengthOfV2Connections === 2

    const isV2DiallerShowing = isMPC || (isMonitoringOrBarging && isEnhancedMonitoringEnabled)

    const [keyPadOpened, setKeyPadOpened] = useState(false)
    const [quickConnectOpened, setQuickConnectOpened] = useState(false)
    const [isQueueListShown, setIsQueueListShown] = useState(false)

    const nextAgentStatus = useSelector((state: RootState) => state.user?.afterCallStatus)
    const softphoneEnabled = useSelector((state: RootState) => state.user?.softphoneEnabled)
    const currentStatus = useSelector(
        (state: RootState) => state.user?.status.name,
    ) as connect.AgentAvailStates
    const otherContacts = useSelector((state: RootState) =>
        state.contacts.some((c) => c.channel !== Channel.VOICE),
    )

    const channel = contact?.channel

    const callV2YouConnection = useSelector(selectYouConnection)

    const { AFTER_CALL_WORK, BUSY } = connect.AgentAvailStates

    const contactLogStatuses = [AFTER_CALL_WORK, BUSY]
    const inContactLogStatus = contactLogStatuses.includes(currentStatus)

    const hasContact = !!contact

    const inCall = connections.length > 0 || connectionsV2.length > 0

    // When enhanced monitoring is disabled in Connect, CTRs are not generated for monitored voice contacts
    // So we do not render out the contact log for these contacts
    const contactLogAvailable = inContactLogStatus || (hasContact && inCall)
    const showAfterCallWork = currentStatus === AFTER_CALL_WORK

    const showContactLog = contactLogAvailable && !isMonitoringOrBarging

    const onEndACW = () => {
        // Temporary batch wrap until we've fully migrated to React 18
        batch(() => {
            nextAgentStatus && !otherContacts && dispatch(setState(nextAgentStatus))
            dispatch(completeCallACW())
        })
    }

    useEffect(() => {
        if (location.pathname.split('/').pop() === 'task') {
            dispatch(clearContact())
        }
    }, [dispatch])

    useEffect(() => {
        const shouldSetCallInfoToContact =
            (inCall || (!inCall && showAfterCallWork)) && channel !== 'VOICE'
        if (shouldSetCallInfoToContact) {
            dispatch(setSelectedCall())
        }
    }, [dispatch])

    useEffect(() => {
        if (quickConnectOpened && (keyPadOpened || isQueueListShown)) {
            setQuickConnectOpened?.(false)
        }
    }, [keyPadOpened, isQueueListShown, quickConnectOpened, setQuickConnectOpened])

    const showCallRecordingControl =
        hasContact &&
        (isEnhancedMonitoringEnabled ? !!callV2YouConnection?.isConnected : !!call?.inCall) &&
        !showAfterCallWork &&
        !isMonitoring &&
        !areYouBeingBarged

    return (
        <HomeContext.Provider
            value={{
                keyPadOpened,
                setKeyPadOpened,
                quickConnectOpened,
                setQuickConnectOpened,
                isQueueListShown,
                setIsQueueListShown,
            }}
        >
            <section aria-label="Home page" className="sa-dialler-grid">
                {softphoneEnabled && !hasMic && (
                    <Popup center noShadow={false}>
                        <div className="row column auto">
                            <h1>Can&apos;t find an active microphone</h1>
                            <ul>
                                <li>Check that you have microphone access</li>
                                <li>
                                    If you have allowed access, try unplugging your headphone from
                                    the PC and trying it in another USB port.
                                </li>
                                <li>
                                    This error can also be caused by your PC running slowly. If your
                                    PC is slow, try closing as many open programs as possible.
                                </li>
                                <li>You can also try refreshing the page to fix this issue.</li>
                            </ul>
                            <Button styling="primary" onClick={clearFails}>
                                OK
                            </Button>
                        </div>
                    </Popup>
                )}
                <div
                    className={classNames({
                        'panel panel-call fixed-height': true,
                        'after-call-work': showAfterCallWork,
                    })}
                >
                    {!!callV2YouConnection?.isForceMuted && (
                        <AlertBanner type="error" icon={muted} iconAlt="Muted microphone icon">
                            Your microphone has been force muted
                        </AlertBanner>
                    )}

                    {showAfterCallWork ? (
                        <AfterCallWork completeCallACW={onEndACW} />
                    ) : (
                        !isV2DiallerShowing && <Dialler showCallSummary={hasTwoParticipants} />
                    )}

                    {!showAfterCallWork &&
                        (inCall && isMonitoringOrBarging
                            ? isEnhancedMonitoringEnabled && (
                                  <Monitoring connections={connectionsV2} />
                              )
                            : isMPC && <MultiPartyCalling connections={connectionsV2} />)}

                    {showCallRecordingControl && <RecordingControl />}

                    {contactLogAvailable && (
                        <>
                            {hasFeature(AppFeatures.DPA) && <DPA />}
                            {hasFeature(AppFeatures.CUSTOMER_INPUTS) && <CustomerInputs />}
                        </>
                    )}

                    {showContactLog ? (
                        <ContactLog channel="VOICE" acw={showAfterCallWork} onSubmit={onEndACW} />
                    ) : (
                        (!isMonitoringOrBarging || isEnhancedMonitoringEnabled) &&
                        hasFeature(AppFeatures.CALL_HISTORY) && <CallHistory channel="VOICE" />
                    )}
                </div>

                <Level>
                    <ContactPanel channel="VOICE" />
                    {hasFeature(AppFeatures.REPORT_ISSUE) && <ReportIssue />}
                </Level>
            </section>
        </HomeContext.Provider>
    )
}

export default Home
