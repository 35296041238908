import { useSelector } from 'react-redux'

import RootState from 'store/state'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import useChatConnections from 'hooks/redux/useChatConnections'

const MISSED_REJECTED_STATES = [connect.AgentErrorStates.MISSED_CALL_AGENT, connect.AgentErrorStates.FAILED_CONNECT_AGENT]

export default function useMissedContact() {
    const currentStatus = useSelector(
        (state: RootState) => state.user?.status.name,
    ) as connect.AgentErrorStates
    const isCallMissed = MISSED_REJECTED_STATES.includes(currentStatus)
    const missedChat = useChatConnections('missed')
    const missedTask = useTaskConnections('missed')

    if (isCallMissed) {
        return {
            channel: 'VOICE' as const,
            currentStatus,
        }
    }

    if (missedChat) {
        return {
            channel: 'CHAT' as const,
            chat: missedChat,
        }
    }

    if (missedTask) {
        return {
            channel: 'TASK' as const,
            task: missedTask,
        }
    }

    return null
}