import { useSelector } from 'react-redux'
import { IChat } from 'store/chat/chat.state'
import RootState from 'store/state'

import { MISSED_REJECTED_STATUSES } from 'utils'

function useChatConnections(status: 'incoming' | 'selected' | 'missed'): IChat | undefined
function useChatConnections(status?: 'current'): IChat[]
function useChatConnections(status?: 'incoming' | 'selected' | 'missed' | 'current') {
    const chats = useSelector<RootState, IChat | IChat[] | undefined>(({ chat, contact }) => {
        if (status === 'incoming')
            return chat?.connections.find((c) => c.status === connect.ContactStateType.CONNECTING)
        if (status === 'selected')
            return contact?.ID ? chat?.connections.find((c) => c.id === contact.ID) : undefined
        if (status === 'missed')
            return chat?.connections.find((c) => MISSED_REJECTED_STATUSES.includes(c.status))
        if (status === 'current')
            return chat?.connections.filter(
                (c) =>
                    ![...MISSED_REJECTED_STATUSES, connect.ContactStateType.CONNECTING].includes(
                        c.status,
                    ),
            )

        return chat.connections
    })

    return chats
}

export default useChatConnections
