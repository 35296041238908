import { useSelector } from 'react-redux'
import RootState from 'store/state'
import { ITask } from 'store/tasks/tasks.state'

import { MISSED_REJECTED_STATUSES } from 'utils'

function useTaskConnections(status: 'incoming' | 'selected' | 'missed'): ITask | undefined
function useTaskConnections(status?: 'current'): ITask[]
function useTaskConnections(status?: 'incoming' | 'selected' | 'missed' | 'current') {
    const tasks = useSelector<RootState, ITask | ITask[] | undefined>(({ tasks, contact }) => {
        if (status === 'incoming')
            return tasks?.connections.find((c) => c.status === connect.ContactStateType.CONNECTING)
        if (status === 'selected')
            return contact?.ID ? tasks?.connections.find((c) => c.id === contact.ID) : undefined
        if (status === 'missed')
            return tasks?.connections.find((c) => MISSED_REJECTED_STATUSES.includes(c.status))
        if (status === 'current')
            return tasks?.connections.filter((c) => ![...MISSED_REJECTED_STATUSES, connect.ContactStateType.CONNECTING].includes(c.status))

        return tasks?.connections
    })

    return tasks
}

export default useTaskConnections
