import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { l as lodashExports } from "./lodash-CzZ181cA.mjs";
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
const body = document.getElementsByTagName("body")[0];
const Tooltip = (props) => {
  const [show, setShow] = useState(false);
  const tooltip = useRef(null);
  const trigger = useRef(null);
  const { className, text, children, rotate, customWidth, openOnFocus } = props;
  useEffect(() => {
    if (!tooltip.current)
      return;
    setTooltip();
  });
  const hideTooltipListener = () => {
    setShow(false);
    document.removeEventListener("keyup", hideTooltipListener);
  };
  const setTooltip = () => {
    document.addEventListener("keyup", hideTooltipListener);
    if (!tooltip.current || !trigger.current)
      return;
    const triggerPos = trigger.current.getBoundingClientRect();
    const tooltipPos = tooltip.current.getBoundingClientRect();
    tooltip.current.style.left = triggerPos.left + triggerPos.width / 2 - tooltipPos.width / 2 + "px";
    const tooltipArrowHeight = 8;
    const topOffset = rotate ? triggerPos.bottom + tooltipArrowHeight : triggerPos.top - tooltipPos.height - tooltipArrowHeight;
    tooltip.current.style.top = `${topOffset}px`;
  };
  const handleTooltipMouseMove = (e) => {
    if (tooltip.current && trigger.current) {
      const { clientX, clientY } = e;
      const tooltipPos = tooltip.current.getBoundingClientRect();
      const triggerPos = trigger.current.getBoundingClientRect();
      const isInsideTooltipOrTrigger = (clientX >= tooltipPos.left || clientX >= triggerPos.left) && (clientX <= tooltipPos.right || clientX <= triggerPos.right) && (clientY >= tooltipPos.top || clientY >= triggerPos.top) && (clientY <= tooltipPos.bottom || clientY <= triggerPos.bottom);
      if (!isInsideTooltipOrTrigger) {
        onHide();
      }
    }
  };
  const throttledMouseMove = lodashExports.throttle(handleTooltipMouseMove, 80);
  useEffect(() => {
    if (show) {
      document.addEventListener("mousemove", throttledMouseMove);
    } else {
      document.removeEventListener("mousemove", throttledMouseMove);
    }
    return () => {
      document.removeEventListener("mousemove", throttledMouseMove);
    };
  }, [show]);
  const onHide = () => setShow(false);
  const onShow = () => setShow(true);
  if (!text)
    return children;
  if (customWidth && !className)
    return children;
  const containerClasses = classNames({
    tooltipContainer: true,
    tooltipRotated: rotate,
    defaultWidth: !customWidth,
    [className]: !!className
  });
  const tooltipBody = /* @__PURE__ */ jsx("div", { className: containerClasses, ref: (r) => tooltip.current = r, children: /* @__PURE__ */ jsx("div", { role: "tooltip", className: "tooltip", id: "sa-tooltip", children: /* @__PURE__ */ jsx("div", { className: "tooltip-inner", children: text }) }) });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    React.cloneElement(children, {
      onMouseEnter: onShow,
      onFocus: onShow,
      onBlur: onHide,
      ref: (r) => trigger.current = r
    }),
    openOnFocus ? (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      /* @__PURE__ */ jsxs("div", { tabIndex: 0, onFocus: onShow, onBlur: onHide, children: [
        text && /* @__PURE__ */ jsxs("div", { className: "tooltipScreenReaderOnly", children: [
          "Tooltip content: ",
          text
        ] }),
        show && text && ReactDOM.createPortal(tooltipBody, body)
      ] })
    ) : show && text && ReactDOM.createPortal(tooltipBody, body)
  ] });
};
export {
  Tooltip as default
};
