import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Agent } from 'components/Dropdowns/agents'
import { Queue } from 'components/Dropdowns/queues'
import * as ContactReducer from 'store/contact/contact.reducer'
import { mergeAttributesIntoContact } from 'store/contact/contact.utils'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import IContactSearchState, {
    AutomatedRulesAuditAction,
    ContactChannelDropdown,
    ContactInitiationMethod,
    IContactSearchForm,
    InteractionAttributesAction,
    QueuedTasksAuditAction,
    TransformedAttachmentsAction,
    UpdateScreenRecordingURL,
} from './contactSearch.state'
import { createDefaultContactSearchForm } from './contactSearch.utils'

const initialState: IContactSearchState = {
    formParams: createDefaultContactSearchForm(),
    interactions: [],
    interactionsLoading: false,
}

export const contactSearchSlice = createSlice({
    name: 'contactSearch',
    initialState,
    reducers: {
        resetFormParams(state, action: PayloadAction<IContactSearchForm | undefined>) {
            return {
                ...state,
                formParams: {
                    ...state.formParams,
                    ...(action.payload ?? createDefaultContactSearchForm()),
                },
            }
        },
        setBargedOnly(state, action: PayloadAction<boolean>) {
            return { ...state, formParams: { ...state.formParams, bargedOnly: action.payload } }
        },
        setFormParams(state, action: PayloadAction<IContactSearchForm>) {
            return { ...state, formParams: action.payload }
        },
        setContactID(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, contactID: action.payload } }
        },
        setSelectedInteraction(state, action: PayloadAction<IInteraction | undefined>) {
            return { ...state, selectedInteraction: action.payload }
        },
        setAppContactId(state, action: PayloadAction<string | undefined>) {
            return { ...state, appContactId: action.payload }
        },
        setAgent(state, action: PayloadAction<Agent | undefined>) {
            return { ...state, formParams: { ...state.formParams, agent: action.payload } }
        },
        setQueue(state, action: PayloadAction<Queue>) {
            return { ...state, formParams: { ...state.formParams, queue: action.payload } }
        },
        setInitiationMethod(
            state,
            action: PayloadAction<
                SADropdownOption<ContactInitiationMethod | undefined> | undefined
            >,
        ) {
            return {
                ...state,
                formParams: { ...state.formParams, initiationMethod: action.payload },
            }
        },
        setCustomerPhone(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, phoneNumber: action.payload } }
        },
        setExternalID(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, externalID: action.payload } }
        },
        setEmail(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, email: action.payload } }
        },
        setEmailSearchType(state, action: PayloadAction<SADropdownOption<string>>) {
            return {
                ...state,
                formParams: { ...state.formParams, emailSearchType: action.payload },
            }
        },
        setStartDate(state, action: PayloadAction<Date>) {
            return {
                ...state,
                formParams: { ...state.formParams, startDate: action.payload.getTime() },
            }
        },
        setStartTime(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, startTime: action.payload } }
        },
        setEndDate(state, action: PayloadAction<Date>) {
            return {
                ...state,
                formParams: { ...state.formParams, endDate: action.payload.getTime() },
            }
        },
        setEndTime(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, endTime: action.payload } }
        },
        setHoldDurationStartTime(state, action: PayloadAction<string>) {
            return {
                ...state,
                formParams: { ...state.formParams, holdDurationStartTime: action.payload },
            }
        },
        setHoldDurationEndTime(state, action: PayloadAction<string>) {
            return {
                ...state,
                formParams: { ...state.formParams, holdDurationEndTime: action.payload },
            }
        },
        setCallDurationStartTime(state, action: PayloadAction<string>) {
            return {
                ...state,
                formParams: { ...state.formParams, callDurationStartTime: action.payload },
            }
        },
        setCallDurationEndTime(state, action: PayloadAction<string>) {
            return {
                ...state,
                formParams: { ...state.formParams, callDurationEndTime: action.payload },
            }
        },
        setChannel(state, action: PayloadAction<ContactChannelDropdown>) {
            return { ...state, formParams: { ...state.formParams, channel: action.payload } }
        },
        setSocialHandle(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, socialHandle: action.payload } }
        },
        setUniqueSocialID(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, uniqueSocialId: action.payload } }
        },
        setChatEmail(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, chatEmail: action.payload } }
        },
        setKeyword(state, action: PayloadAction<string>) {
            return { ...state, formParams: { ...state.formParams, keyword: action.payload } }
        },
        setChatOrderNumber(state, action: PayloadAction<string>) {
            return {
                ...state,
                formParams: { ...state.formParams, chatOrderNumber: action.payload },
            }
        },
        updateScreenRecording(state, action: PayloadAction<UpdateScreenRecordingURL>) {
            return { ...state!, screenRecordingURL: action.payload.signedUrl }
        },
        loadingInteractions(state, action: PayloadAction<boolean>) {
            return { ...state, interactionsLoading: action.payload }
        },
        setInteractions(state, action: PayloadAction<IInteraction[]>) {
            return { ...state, interactions: action.payload, interactionsLoading: false }
        },
        setQueuedTasksAudit(state, action: PayloadAction<QueuedTasksAuditAction>) {
            const { selectedInteraction, interactions } = state

            const { interactionID, queuedTasksAudit } = action.payload

            const updatedInteractions = interactions.map((interaction) => {
                return interaction.ID === interactionID
                    ? { ...interaction, queuedTasksAudit }
                    : interaction
            })

            const updatedSelectedInteraction =
                selectedInteraction?.ID === interactionID
                    ? { ...selectedInteraction, queuedTasksAudit }
                    : selectedInteraction

            return {
                ...state,
                interactions: updatedInteractions,
                selectedInteraction: updatedSelectedInteraction,
            }
        },
        setAutomatedRulesAudit(state, action: PayloadAction<AutomatedRulesAuditAction>) {
            const { selectedInteraction, interactions } = state

            const { interactionID, automatedRulesAudit } = action.payload

            const updatedInteractions = interactions.map((interaction) => {
                return interaction.ID === interactionID
                    ? { ...interaction, automatedRulesAudit }
                    : interaction
            })

            const updatedSelectedInteraction =
                selectedInteraction?.ID === interactionID
                    ? { ...selectedInteraction, automatedRulesAudit }
                    : selectedInteraction

            return {
                ...state,
                interactions: updatedInteractions,
                selectedInteraction: updatedSelectedInteraction,
            }
        },
        addTransformedAttachments(state, action: PayloadAction<TransformedAttachmentsAction>) {
            const { interactionID, transformedAttachments } = action.payload

            const { interactions, selectedInteraction } = state

            const updatedInteractions = interactions.map((interaction) =>
                interaction.messageId === interactionID
                    ? {
                          ...interaction,
                          emailMessages: interaction.emailMessages?.map((emailMessage) =>
                              emailMessage.messageId === interactionID
                                  ? { ...emailMessage, attachments: transformedAttachments }
                                  : emailMessage,
                          ),
                      }
                    : interaction,
            )

            const updatedSelectedInteraction = selectedInteraction
                ? {
                      ...selectedInteraction,
                      emailMessages: selectedInteraction.emailMessages?.map((emailMessage) =>
                          emailMessage.messageId === interactionID
                              ? { ...emailMessage, attachments: transformedAttachments }
                              : emailMessage,
                      ),
                  }
                : selectedInteraction

            return {
                ...state,
                interactions: updatedInteractions,
                selectedInteraction: updatedSelectedInteraction,
            }
        },
        setInteractionAttributes(state, action: PayloadAction<InteractionAttributesAction>) {
            const { interactionID, attributes } = action.payload

            const { interactions, selectedInteraction } = state

            const updatedInteractions = interactions.map((interaction) =>
                interaction.ID === interactionID
                    ? { ...interaction, attributes, attributesUpdated: true }
                    : interaction,
            )

            const updatedSelectedInteraction =
                selectedInteraction?.ID === interactionID
                    ? { ...selectedInteraction, attributes, attributesUpdated: true }
                    : selectedInteraction

            return {
                ...state,
                interactions: updatedInteractions,
                selectedInteraction: updatedSelectedInteraction,
            }
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(ContactReducer.getContact, (state, action) => {
            return {
                ...state,
                interactions: state.interactions.map((interaction) => {
                    if (interaction.ID === action.payload.ID) {
                        return {
                            ...interaction,
                            ...action.payload,
                        }
                    }
                    return interaction
                }),
            }
        }),
            addCase(ContactReducer.updateContactAttributes, (state, action) => {
                return {
                    ...state,
                    interactions: state.interactions.map((interaction) => {
                        if (interaction.ID === action.payload.ID) {
                            return mergeAttributesIntoContact(
                                interaction,
                                action.payload.attributes,
                            ) as IInteraction
                        }
                        return interaction
                    }),
                }
            })
    },
})

export const {
    resetFormParams,
    setFormParams,
    loadingInteractions,
    setAgent,
    setAppContactId,
    setBargedOnly,
    setCallDurationEndTime,
    setCallDurationStartTime,
    setChannel,
    setChatEmail,
    setChatOrderNumber,
    setContactID,
    setCustomerPhone,
    setEmail,
    setEmailSearchType,
    setEndDate,
    setEndTime,
    setExternalID,
    setHoldDurationEndTime,
    setHoldDurationStartTime,
    setInitiationMethod,
    setInteractions,
    setKeyword,
    setQueue,
    setQueuedTasksAudit,
    setSelectedInteraction,
    setSocialHandle,
    setStartDate,
    setStartTime,
    setUniqueSocialID,
    setAutomatedRulesAudit,
    addTransformedAttachments,
    setInteractionAttributes,
    updateScreenRecording
} = contactSearchSlice.actions

export type ContactSearchAction = ReturnType<
    (typeof contactSearchSlice.actions)[keyof typeof contactSearchSlice.actions]
>

export default contactSearchSlice.reducer
