import { Button, TimeElapsed } from '@missionlabs/smartagent-app-components'
import React from 'react'
import PauseIcon from './Pause-noFill.svg'
import PlayIcon from './Play-noFill.svg'
interface Props {
    elapsed?: number
    total: number
    isPlaying: boolean
    playPause: () => void
}

export const Controls: React.FC<Props> = ({ elapsed, total, isPlaying, playPause }) => {
    return (
        <div
            className="sa-audioplayer-controls-wrapper"
            data-testid="screen-recording-audio-controls"
        >
            <div className="sa-audioplayer-controls" data-testid="sa-audioplayer-controls">
                <TimeElapsed
                    ariaLive="off"
                    hours
                    format="hh:mm:ss"
                    noTick
                    date={TimeElapsed.from(elapsed ?? 0)}
                />
                <span>{' / '}</span>
                <TimeElapsed
                    ariaLive="off"
                    hours
                    format="hh:mm:ss"
                    noTick
                    date={TimeElapsed.from(total)}
                />

                <Button styling="blank" onClick={playPause}>
                    <img
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt={isPlaying ? 'Pause' : 'Play'}
                        title={isPlaying ? 'Pause' : 'Play'}
                    />
                </Button>
            </div>
        </div>
    )
}
