import { IMetricsConfig, IQueueMetricData } from 'store/metrics/metrics.state'
import { IQueue } from 'store/settings/settings.state'
import { Metric, Queue } from 'store/user/user.state'

import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

import allQueueColumns from './Columns/allQueueColumns'

const getMetricStats = (name: string, queue?: IQueueMetricData): Metric | { Value: number } => {
    if (!queue) return { Value: 0 }
    const metric = queue.stats?.find((metric) => metric.Metric.Name === name) ?? { Value: 0 }
    return metric
}

const getTotal = (queues: IQueueMetricData[], field: keyof IQueueMetricData): number => {
    return queues.reduce((total, queue) => ((queue[field] ?? 0) as number) + total, 0)
}

const hasSLAStatus = (queueMetrics: IQueueMetricData[], name: string): boolean => {
    return queueMetrics.some((metric) => metric.slaStatus === name)
}

const roundPercentage = (percentagRejected: number) => {
    const rounded = (percentagRejected * 100).toFixed(2)
    if (rounded.endsWith('.00')) {
        return parseInt(rounded)
    } else {
        return rounded
    }
}

const getRealTimeStats = (ID: string, queueName: string, metrics?: IQueueMetricData) => {
    return {
        ID,
        queueName,
        online: getMetricStats('AGENTS_ONLINE', metrics),
        onCall: getMetricStats('AGENTS_ON_CONTACT', metrics),
        npt: getMetricStats('AGENTS_NON_PRODUCTIVE', metrics),
        acw: getMetricStats('AGENTS_AFTER_CONTACT_WORK', metrics),
        error: getMetricStats('AGENTS_ERROR', metrics),
        available: getMetricStats('AGENTS_AVAILABLE', metrics),
        inQueue: getMetricStats('CONTACTS_IN_QUEUE', metrics),
        availability: getMetricStats('SLOTS_AVAILABLE', metrics),
        scheduled: getMetricStats('CONTACTS_SCHEDULED', metrics),
        oldest: getMetricStats('OLDEST_CONTACT_AGE', metrics),
        queued: metrics?.received ?? 0,
        handled: metrics?.handled ?? 0,
        abandoned: metrics?.rejected ?? 0,
        percentageAbandoned: metrics?.percentageRejected
            ? `${roundPercentage(metrics.percentageRejected)}%`
            : `0%`,
        sla: metrics?.sla ?? 0,
        slaStatus: metrics?.slaStatus ?? '',
        avgHandledTime: metrics?.avgHandledTime ?? 0,
        percentageRejectedStatus: metrics?.percentageRejectedStatus ?? '',
        avgHandledTimeStatus: metrics?.avgHandledTimeStatus ?? '',
    }
}

const buildSummaryQueue = (
    queueMetrics: IQueueMetricData[] | undefined,
    showQueueSummary: boolean,
) => {
    const summary = queueMetrics?.find((queue) => queue.queueID === 'SUMMARY')
    if (!showQueueSummary || !queueMetrics || !summary) return

    const queuesWithCalls = queueMetrics.filter((metric) => metric.handled > 0).length
    const rejectedCalls = getTotal(queueMetrics, 'rejected')
    const handledCalls = getTotal(queueMetrics, 'handled')

    return {
        // pad to force to top when sorted
        ...getRealTimeStats('SUMMARY', '    Summary', summary),
        queued: getTotal(queueMetrics, 'received'),
        handled: getTotal(queueMetrics, 'handled'),
        abandoned: rejectedCalls,
        avgHandledTime: getTotal(queueMetrics, 'avgHandledTime'),
        percentageAbandoned: roundPercentage(rejectedCalls / (rejectedCalls + handledCalls)) + '%',
        slaStatus: hasSLAStatus(queueMetrics, 'alert')
            ? 'alert'
            : hasSLAStatus(queueMetrics, 'warning')
              ? 'warning'
              : hasSLAStatus(queueMetrics, 'ontarget')
                ? 'ontarget'
                : 'default',
        sla: getTotal(queueMetrics, 'sla') / queuesWithCalls,
    }
}

const getLongPollingMetricData = ({
    queues,
    queueIDs,
    statsQueueIds,
    queueMetrics,
    showQueueSummary,
    isRTMPage,
    metricsConfig,
}: {
    queues: IQueue[] | undefined
    queueIDs: string[] | undefined
    statsQueueIds: string[] | undefined
    queueMetrics: IQueueMetricData[] | undefined
    showQueueSummary: boolean
    isRTMPage: boolean
    metricsConfig?: IMetricsConfig
}) => {
    if (!queues) return []
    // Always filter by the queueIDs from the filter store
    let filteredQueues = queueIDs
        ? queues.filter((queue) => queueIDs.find((filterIds) => queue.Id === filterIds))
        : queues

    // Only filter by statsQueueIds when not on RTM page
    filteredQueues =
        !isRTMPage && statsQueueIds
            ? filteredQueues.filter((queue) =>
                  statsQueueIds.find((filterIds) => queue.Id === filterIds),
              )
            : filteredQueues

    if (metricsConfig) {
        const defaultMetrics = metricsConfig.columns?.reduce((acc, col) => {
            const key = col?.ID || col?.connectConfig?.Name

            return {
                ...acc,
                [key]: {
                    Value: 0,
                    Metric: {
                        Name: key,
                    },
                },
            }
        }, {})

        const queueData = filteredQueues.map((queue) => {
            const metrics = queueMetrics?.find((metrics) => metrics.queueID === queue.Id)
            const initialQueueData = {
                ...defaultMetrics,
                queueName: metrics?.queueName,
            }

            return (
                metrics?.data?.reduce((acc, metric) => {
                    const key = metric.ID || metric.Metric.Name

                    return {
                        ...acc,
                        [key]: metric,
                    }
                }, initialQueueData) || initialQueueData
            )
        })

        return queueData.filter((queue) => queue.queueName)
    }

    const queueData = filteredQueues.map((queue) => {
        const metrics = queueMetrics?.find((metrics) => metrics.queueID === queue.Id)
        return getRealTimeStats(queue.Id, queue.Name, metrics)
    })

    const queueSummary = buildSummaryQueue(queueMetrics, showQueueSummary)

    return queueSummary ? [queueSummary, ...queueData] : [...queueData]
}

const getToolbarOptions = (
    queues: IQueue[] | undefined,
    queueIDs: string[] | undefined,
    isRTMPage: boolean,
    userQueues: Queue[] | undefined,
) => {
    let options = queues
        ? queues.map((queue) => ({
              label: queue.Name,
              checked: queueIDs ? queueIDs.includes(queue.Id) : true,
              value: queue.Id,
          }))
        : undefined

    options =
        !isRTMPage && userQueues
            ? options?.filter((queue) =>
                  userQueues.find(
                      (userQueue) => queue.value === userQueue.queueId.split('/queue/')[1],
                  ),
              )
            : options

    return options
}

const statsColumnsNames = [
    'Queue',
    'Online',
    'On Contact',
    'Available',
    'ACW',
    'Available',
    'In Queue',
    'Oldest Time',
    'Handled',
    'Abandoned',
    'AHT',
    'SLA',
]
const filteredQueueColumns = allQueueColumns.filter((column: SATableColumn) =>
    statsColumnsNames.includes(column.name),
)

export {
    filteredQueueColumns,
    getLongPollingMetricData,
    getRealTimeStats,
    getToolbarOptions,
    getTotal,
    hasSLAStatus,
    roundPercentage,
}
