import { CONTACT_LOG, LOG_GROUP_ATTRIBUTE_NAME } from 'constants/index'
import useApp from 'hooks/redux/useApp'
import useContact from 'hooks/redux/useContact'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { loadForm } from 'store/app/app.actions'
import { FormState } from 'store/app/app.state'
import { LoadingStates } from 'store/state'
import { getFormName } from 'utils'

const useContactLogForm = (contactLogGroup?: string): FormState => {
    const app = useApp()
    const contact = useContact()
    const dispatch = useDispatch()

    const logGroup: string | undefined = contactLogGroup
        ? contactLogGroup
        : useMemo(() => contact?.attributes?.[LOG_GROUP_ATTRIBUTE_NAME], [contact])

    const formName: string = useMemo(() => {
        return getFormName(logGroup, app)
    }, [logGroup])
    const contactLog: FormState | undefined = useMemo(
        () => app?.forms[formName],
        [app?.forms, formName],
    )

    useEffect(() => {
        if (!contactLog
            || (contactLog.state === LoadingStates.ERROR
                && contactLog.errorState?.timeUntilNextRetry
                && Date.now() > contactLog.errorState.timeUntilNextRetry
            )
        ) {
            dispatch(loadForm(CONTACT_LOG, logGroup))
        }
    }, [contactLog, dispatch])
    return contactLog || {}
}

export default useContactLogForm
