import { IEmailTemplate } from 'store/channelSettings/email/email.state'
import { logout } from 'store/user/user.reducer'
import { AuditLogsResponse } from 'views/AdminSettings/AuditLogs/types'
import { CallbackQueue } from 'views/Settings/Queues/types'

import { ContactFlowSummary } from '@aws-sdk/client-connect'
import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { ISettings } from '@missionlabs/smartagent-lib-shared/build/settings'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import SettingsState, {
    AuthProviderConfig,
    IAppFeature,
    IContactFlow,
    IGlobalLine,
    IGroup,
    IHierarchyGroup,
    IPermission,
    IQueue,
    IReport,
    IRoutingProfile,
    IUserHierarchy,
    Line,
    SetFilteredLinesAction,
    SmartAgentUser,
} from './settings.state'

const initialState: Partial<SettingsState> = {}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setRingingDevice(state, action: PayloadAction<SADropdownOption | null>) {
            return {
                ...state,
                ringingDevice: action.payload,
            }
        },
        getGlobalLine(state, action: PayloadAction<IGlobalLine>) {
            return {
                ...state,
                globalLine: action.payload,
                globalLineError: false,
                globalLineLoading: false,
            }
        },
        getGlobalLineError(state) {
            return {
                ...state,
                globalLineError: true,
                globalLineLoading: false,
            }
        },
        updateGlobalLine(state, action: PayloadAction<IGlobalLine>) {
            return {
                ...state,
                globalLine: action.payload,
                globalLineLoading: false,
            }
        },
        getGlobalLineLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                globalLineLoading: action.payload,
            }
        },
        getLines(state, action: PayloadAction<Line[]>) {
            return {
                ...state,
                lines: action.payload,
                linesLoading: false,
                filteredLines: {
                    keyword: '',
                    status: null,
                },
            }
        },
        getLinesLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                linesLoading: action.payload,
            }
        },
        updateLines(state, action: PayloadAction<Line>) {
            return {
                ...state,
                lines: state!.lines!.map((line) =>
                    line.ID === action.payload.ID ? { ...line, ...action.payload } : line,
                ),
            }
        },
        setFilteredLines(state, action: PayloadAction<SetFilteredLinesAction>) {
            return {
                ...state,
                filteredLines: action.payload,
            }
        },
        updateLinesLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                updateLinesLoading: action.payload,
            }
        },
        getReports(state, action: PayloadAction<IReport[]>) {
            return {
                ...state,
                reports: action.payload,
            }
        },
        getReportsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                reportsLoading: action.payload,
            }
        },
        generateReportsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                generateReportsLoading: action.payload,
            }
        },
        getRoutingProfiles(state, action: PayloadAction<IRoutingProfile[]>) {
            return {
                ...state,
                routingProfiles: action.payload,
            }
        },
        getSecurityGroups(state, action: PayloadAction<IGroup[]>) {
            return {
                ...state,
                securityGroups: action.payload,
            }
        },
        getUserHierarchy(state, action: PayloadAction<IUserHierarchy>) {
            return {
                ...state,
                userHierarchy: action.payload,
            }
        },
        getHierarchyGroups(state, action: PayloadAction<IHierarchyGroup[]>) {
            return {
                ...state,
                hierarchyGroups: action.payload,
            }
        },
        getUsers(state, action: PayloadAction<SmartAgentUser[]>) {
            return {
                ...state,
                users: action.payload,
                usersLoading: false,
                usersError: false,
            }
        },
        getUsersLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                usersLoading: action.payload,
            }
        },
        getUsersError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                usersError: action.payload,
                usersLoading: false,
            }
        },
        deleteUser(state, action: PayloadAction<SmartAgentUser>) {
            return {
                ...state,
                users: state!.users!.filter((agent) => agent.ID !== action.payload.ID),
                userDeleting: null,
                userDeletingInProgress: false,
            }
        },
        deleteUserLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userDeletingInProgress: action.payload,
            }
        },
        deleteUserError(state, action: PayloadAction<string>) {
            return {
                ...state,
                userDeletingError: action.payload,
                userDeletingInProgress: false,
                userDeleting: null,
            }
        },
        deletingUser(state, action: PayloadAction<SmartAgentUser | null>) {
            return {
                ...state,
                userDeleting: action.payload,
            }
        },
        disableUser(state, action: PayloadAction<SmartAgentUser>) {
            return {
                ...state,
                users: state!.users!.map((agent) =>
                    agent.ID !== action.payload.ID
                        ? agent
                        : {
                              ...agent,
                              disabled: true,
                          },
                ),
                userDisabling: null,
                userDisablingInProgress: false,
            }
        },
        disableUserLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userDisablingInProgress: action.payload,
            }
        },
        disableUserError(state, action: PayloadAction<string>) {
            return {
                ...state,
                userDisablingError: action.payload,
                userDisablingInProgress: false,
                userDisabling: null,
            }
        },
        disablingUser(state, action: PayloadAction<SmartAgentUser | null>) {
            return {
                ...state,
                userDisabling: action.payload,
            }
        },
        enableUser(state, action: PayloadAction<SmartAgentUser>) {
            return {
                ...state,
                users: state!.users!.map((agent) =>
                    agent.ID !== action.payload.ID
                        ? agent
                        : {
                              ...agent,
                              disabled: false,
                          },
                ),
                userEnabling: null,
                userEnablingInProgress: false,
            }
        },
        enableUserLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userEnablingInProgress: action.payload,
            }
        },
        enableUserError(state, action: PayloadAction<string>) {
            return {
                ...state,
                userEnablingError: action.payload,
                userEnablingInProgress: false,
                userEnabling: null,
            }
        },
        enablingUser(state, action: PayloadAction<SmartAgentUser | null>) {
            return {
                ...state,
                userEnabling: action.payload,
            }
        },
        addingUserLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userAddingInProgress: action.payload,
            }
        },
        addUserError(state, action: PayloadAction<string>) {
            return {
                ...state,
                userAddingError: action.payload,
                userAddingInProgress: false,
            }
        },
        addingUser(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userAdding: action.payload,
            }
        },
        addUser(state, action: PayloadAction<SmartAgentUser>) {
            return {
                ...state,
                users: [...state!.users!, action.payload],
                userAddingError: '',
                userAddingInProgress: false,
                userAdding: false,
            }
        },
        editUserRefresh(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userRefreshing: action.payload,
            }
        },
        editingUserLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userEditingInProgress: action.payload,
            }
        },
        editUserError(state, action: PayloadAction<string>) {
            return {
                ...state,
                userEditingInProgress: false,
                userEditing: null,
                userEditingError: action.payload,
            }
        },
        editingUser(state, action: PayloadAction<SmartAgentUser | null>) {
            return {
                ...state,
                userEditing: action.payload,
                userRefreshing: false,
                userEditingError: undefined,
            }
        },
        editUser(state, action: PayloadAction<SmartAgentUser>) {
            return {
                ...state,
                userEditing: null,
                userEditingInProgress: false,
                users: state?.users?.map((user) =>
                    user.ID === action.payload.ID ? action.payload : user,
                ),
            }
        },
        getQueues(state, action: PayloadAction<IQueue[] | undefined>) {
            return {
                ...state,
                queues: action.payload,
            }
        },
        getEnabledQueues(state, action: PayloadAction<IQueue[] | undefined>) {
            return {
                ...state,
                enabledQueues: action.payload,
            }
        },
        getEmailTemplates(state, action: PayloadAction<IEmailTemplate[]>) {
            return {
                ...state,
                emailTemplates: action.payload,
            }
        },
        getAllowedAttachmentTypes(state, action: PayloadAction<Record<string, string>>) {
            return {
                ...state,
                allowedAttachmentTypes: action.payload,
            }
        },
        getContactFlows(state, action: PayloadAction<IContactFlow[]>) {
            return {
                ...state,
                contactFlows: action.payload,
            }
        },
        getConnectContactFlows(state, action: PayloadAction<ContactFlowSummary[]>) {
            return {
                ...state,
                connectContactFlows: action.payload,
            }
        },
        getPermissions(state, action: PayloadAction<IPermission[]>) {
            return {
                ...state,
                permissions: action.payload,
            }
        },
        getPermissionsError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                permissionsError: action.payload,
            }
        },
        getFeatureList(state, action: PayloadAction<IAppFeature[]>) {
            return {
                ...state,
                featureList: action.payload,
            }
        },
        getCognitoConfig(state, action: PayloadAction<AuthProviderConfig>) {
            return {
                ...state,
                cognitoConfig: action.payload.config,
            }
        },
        getSettings(state, action: PayloadAction<ISettings>) {
            return {
                ...state,
                settingsLoading: false,
                settings: action.payload,
            }
        },
        getSettingsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                settingsLoading: action.payload,
            }
        },
        editSettingsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                editSettingsLoading: action.payload,
            }
        },
        editSettings(state, action: PayloadAction<Partial<ISettings>>) {
            return {
                ...state,
                editSettingsLoading: false,
                settings: action.payload as ISettings,
            }
        },
        enableWebHID(state) {
            return {
                ...state,
                userHasEnabledWebHID: true,
            }
        },
        disableWebHID(state) {
            return {
                ...state,
                userHasEnabledWebHID: false,
            }
        },
        getAuditLogsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                auditLogs: {
                    logs: [],
                    auditLogsLoading: action.payload,
                    auditLogsError: false,
                    orderBy: '',
                    sortDirection: '',
                    filters: {},
                    totalTableRowCount: 0,
                    currentTablePage: 1,
                },
            }
        },
        getAuditLogs(state, action: PayloadAction<AuditLogsResponse>) {
            return {
                ...state,
                auditLogs: {
                    auditLogsLoading: false,
                    logs: action.payload.data,
                    auditLogsError: false,
                    orderBy: action.payload.orderBy,
                    sortDirection: action.payload.sortDirection,
                    filters: action.payload.filters,
                    totalTableRowCount: action.payload.totalCount,
                    currentTablePage: action.payload.page,
                },
            }
        },
        getAuditLogsError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                auditLogs: {
                    logs: [],
                    auditLogsLoading: false,
                    auditLogsError: action.payload,
                    orderBy: '',
                    sortDirection: '',
                    filters: {},
                    totalTableRowCount: 0,
                    currentTablePage: 1,
                },
            }
        },
        getSettingNamesForAuditLogsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                settingNamesForAuditLogs: {
                    settingNamesLoading: action.payload,
                    settingNames: [],
                    settingNamesError: false,
                },
            }
        },
        getSettingNamesForAuditLogs(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                settingNamesForAuditLogs: {
                    settingNamesLoading: false,
                    settingNames: action.payload,
                    settingNamesError: false,
                },
            }
        },
        getSettingNamesForAuditLogsError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                settingNamesForAuditLogs: {
                    settingNamesLoading: false,
                    settingNames: [],
                    settingNamesError: action.payload,
                },
            }
        },
        getUsersListForAuditLogsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                usersListForAuditLogs: {
                    usersListLoading: action.payload,
                    usersList: [],
                    usersListError: false,
                },
            }
        },
        getUsersListForAuditLogs(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                usersListForAuditLogs: {
                    usersListLoading: false,
                    usersList: action.payload,
                    usersListError: false,
                },
            }
        },
        getUsersListForAuditLogsError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                usersListForAuditLogs: {
                    usersListLoading: false,
                    usersList: [],
                    usersListError: action.payload,
                },
            }
        },
        getCallbackQueuesLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                callbackQueues: {
                    queues: [],
                    getCallbackQueuesLoading: action.payload,
                    getCallbackQueuesError: false,
                    updateCallbackQueueLoading: false,
                    updateCallbackQueueError: false,
                },
            }
        },
        getCallbackQueues(state, action: PayloadAction<CallbackQueue[]>) {
            return {
                ...state,
                callbackQueues: {
                    getCallbackQueuesLoading: false,
                    queues: action.payload,
                    getCallbackQueuesError: false,
                    updateCallbackQueueLoading: false,
                    updateCallbackQueueError: false,
                },
            }
        },
        getCallbackQueuesError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                callbackQueues: {
                    queues: [],
                    getCallbackQueuesLoading: false,
                    getCallbackQueuesError: action.payload,
                    updateCallbackQueueLoading: false,
                    updateCallbackQueueError: false,
                },
            }
        },
        updateCallbackQueueLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                callbackQueues: {
                    getCallbackQueuesLoading: false,
                    getCallbackQueuesError: false,
                    queues: state.callbackQueues?.queues!,
                    updateCallbackQueueLoading: action.payload,
                    updateCallbackQueueError: false,
                },
            }
        },
        updateCallbackQueue(state, action: PayloadAction<CallbackQueue>) {
            const restQueues = state.callbackQueues?.queues.filter(
                (queue) => queue.queueId !== action.payload.queueId,
            )
            return {
                ...state,
                callbackQueues: {
                    getCallbackQueuesLoading: false,
                    getCallbackQueuesError: false,
                    queues: [...restQueues!, action.payload],
                    updateCallbackQueueLoading: false,
                    updateCallbackQueueError: false,
                },
            }
        },
        updateCallbackQueueError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                callbackQueues: {
                    getCallbackQueuesLoading: false,
                    getCallbackQueuesError: false,
                    queues: state.callbackQueues?.queues!,
                    updateCallbackQueueLoading: false,
                    updateCallbackQueueError: action.payload,
                },
            }
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(logout, (state) => {
            return {
                ringingDevice: state?.ringingDevice,
                userHasEnabledWebHID: state?.userHasEnabledWebHID,
            }
        })
    },
})

export const {
    setRingingDevice,
    getGlobalLine,
    getGlobalLineError,
    updateGlobalLine,
    getGlobalLineLoading,
    getLines,
    getLinesLoading,
    updateLines,
    setFilteredLines,
    updateLinesLoading,
    getReports,
    getReportsLoading,
    generateReportsLoading,
    getRoutingProfiles,
    getSecurityGroups,
    getUserHierarchy,
    getHierarchyGroups,
    getUsers,
    getUsersLoading,
    getUsersError,
    deleteUser,
    deleteUserLoading,
    deleteUserError,
    deletingUser,
    disableUser,
    disableUserLoading,
    disableUserError,
    disablingUser,
    enableUser,
    enableUserLoading,
    enableUserError,
    enablingUser,
    addingUserLoading,
    addUserError,
    addingUser,
    addUser,
    editUserRefresh,
    editingUserLoading,
    editUserError,
    editingUser,
    editUser,
    getQueues,
    getEnabledQueues,
    getEmailTemplates,
    getAllowedAttachmentTypes,
    getContactFlows,
    getConnectContactFlows,
    getPermissions,
    getPermissionsError,
    getFeatureList,
    getCognitoConfig,
    getSettings,
    getSettingsLoading,
    editSettingsLoading,
    editSettings,
    enableWebHID,
    disableWebHID,
    getAuditLogsLoading,
    getAuditLogs,
    getAuditLogsError,
    getSettingNamesForAuditLogsLoading,
    getSettingNamesForAuditLogs,
    getSettingNamesForAuditLogsError,
    getUsersListForAuditLogsLoading,
    getUsersListForAuditLogs,
    getUsersListForAuditLogsError,
    getCallbackQueuesLoading,
    getCallbackQueues,
    getCallbackQueuesError,
    updateCallbackQueueLoading,
    updateCallbackQueue,
    updateCallbackQueueError,
} = settingsSlice.actions

export type SettingsAction = ReturnType<
    (typeof settingsSlice.actions)[keyof typeof settingsSlice.actions]
>

export default settingsSlice.reducer
