import useMissedContact from 'hooks/redux/useMissedContact'

import MissedChat from 'views/Chat/MissedChat'
import MissedCall from 'views/Home/Dialler/MissedCall'
import MissedTask from 'views/Tasks/MissedTask'

const MissedContact = () => {
    const missedContact = useMissedContact()

    switch (missedContact?.channel) {
        case 'VOICE':
            return <MissedCall currentStatus={missedContact.currentStatus} />

        case 'TASK':
            return <MissedTask task={missedContact.task} />

        case 'CHAT':
            return <MissedChat chat={missedContact.chat} />

        default:
            return null
    }
}

export default MissedContact
