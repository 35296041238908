import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { v4 } from "uuid";
import { l as lodashExports } from "./lodash-CzZ181cA.mjs";
const LargePlay = "data:image/svg+xml,%3csvg%20width='32'%20height='32'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20250'%3e%3cpath%20fill='none'%20d='M-1-1h40v40H-1z'/%3e%3cpath%20fill='%23788087'%20d='M.443%201.417L207.557%20120.99.443%20240.583V1.417z'/%3e%3c/svg%3e";
const LargePause = "data:image/svg+xml,%3csvg%20width='32'%20height='32'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3e%3cpath%20fill='%23788087'%20d='M162.64%20148.34h56.04v215.31h-56.04V148.34zM293.36%20148.34h56v215.31h-56V148.34z'/%3e%3c/svg%3e";
const LargeReplay = "data:image/svg+xml,%3csvg%20fill='%23788087'%20width='32'%20height='32'%20version='1.1'%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20viewBox='0%200%20367.136%20367.136'%20xml:space='preserve'%20transform='rotate(90)matrix(-1,%200,%200,%201,%200,%200)'%3e%3cg%20id='SVGRepo_bgCarrier'%20stroke-width='0'%3e%3c/g%3e%3cg%20id='SVGRepo_tracerCarrier'%20stroke-linecap='round'%20stroke-linejoin='round'%3e%3c/g%3e%3cg%20id='SVGRepo_iconCarrier'%3e%3cpath%20d='M336.554,86.871c-11.975-18.584-27.145-34.707-44.706-47.731L330.801,0H217.436v113.91L270.4,60.691%20c40.142,28.131,65.042,74.724,65.042,124.571c0,83.744-68.13,151.874-151.874,151.874S31.694,269.005,31.694,185.262%20c0-58.641,32.781-111.009,85.551-136.669l-13.119-26.979C73.885,36.318,48.315,59.1,30.182,87.494%20c-18.637,29.184-28.488,62.991-28.488,97.768c0,100.286,81.588,181.874,181.874,181.874s181.874-81.588,181.874-181.874%20C365.442,150.223,355.453,116.201,336.554,86.871z'%3e%3c/path%3e%3c/g%3e%3c/svg%3e";
const Play = "data:image/svg+xml,%3csvg%20width='20'%20height='20'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20250%20250'%3e%3cpath%20fill='none'%20d='M-1-1h40v40H-1z'/%3e%3cpath%20fill='%23458DC9'%20d='M.443%201.417L207.557%20120.99.443%20240.583V1.417z'/%3e%3c/svg%3e";
const Pause = "data:image/svg+xml,%3csvg%20width='24'%20height='24'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3e%3cpath%20fill='%23458DC9'%20d='M162.64%20148.34h56.04v215.31h-56.04V148.34zM293.36%20148.34h56v215.31h-56V148.34z'/%3e%3c/svg%3e";
const Replay = "data:image/svg+xml,%3csvg%20fill='%23458DC9'%20width='20'%20height='20'%20version='1.1'%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20viewBox='0%200%20367.136%20367.136'%20xml:space='preserve'%20transform='rotate(90)matrix(-1,%200,%200,%201,%200,%200)'%3e%3cg%20id='SVGRepo_bgCarrier'%20stroke-width='0'%3e%3c/g%3e%3cg%20id='SVGRepo_tracerCarrier'%20stroke-linecap='round'%20stroke-linejoin='round'%3e%3c/g%3e%3cg%20id='SVGRepo_iconCarrier'%3e%3cpath%20d='M336.554,86.871c-11.975-18.584-27.145-34.707-44.706-47.731L330.801,0H217.436v113.91L270.4,60.691%20c40.142,28.131,65.042,74.724,65.042,124.571c0,83.744-68.13,151.874-151.874,151.874S31.694,269.005,31.694,185.262%20c0-58.641,32.781-111.009,85.551-136.669l-13.119-26.979C73.885,36.318,48.315,59.1,30.182,87.494%20c-18.637,29.184-28.488,62.991-28.488,97.768c0,100.286,81.588,181.874,181.874,181.874s181.874-81.588,181.874-181.874%20C365.442,150.223,355.453,116.201,336.554,86.871z'%3e%3c/path%3e%3c/g%3e%3c/svg%3e";
const useMediaPlayback = (getUrl) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, _] = useState(false);
  const [durationInSeconds, setDurationInSeconds] = useState(null);
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const [hasEnded, setHasEnded] = useState(false);
  const [error, setError] = useState("");
  const [mediaUrl, setMediaUrl] = useState(null);
  const mediaPlayer = useRef();
  const reactPlayerId = useMemo(() => `id-${v4()}`, []);
  const handleGetMediaUrlFailure = () => {
    setIsLoading(false);
    setError("Failed to get media URL");
  };
  async function getMediaUrl() {
    const url = await getUrl().catch(() => handleGetMediaUrlFailure());
    if (!url)
      return handleGetMediaUrlFailure();
    setIsPlaying(true);
    setIsLoading(false);
    setMediaUrl(url);
  }
  useEffect(() => {
    if (isLoading) {
      getMediaUrl();
    }
  }, [isLoading]);
  useEffect(() => {
    var _a;
    function togglePlayback() {
      setIsPlaying((prev) => !prev);
    }
    if (mediaUrl)
      (_a = document.querySelector(`#${reactPlayerId}`)) == null ? void 0 : _a.addEventListener("click", togglePlayback);
    return () => {
      var _a2;
      return (_a2 = document.querySelector(`#${reactPlayerId}`)) == null ? void 0 : _a2.removeEventListener("click", togglePlayback);
    };
  }, [mediaUrl]);
  const playbackImgInfo = useMemo(() => {
    if (hasEnded)
      return { src: Replay, largeSrc: LargeReplay, alt: "Replay" };
    return {
      src: isPlaying ? Pause : Play,
      largeSrc: isPlaying ? LargePause : LargePlay,
      alt: isPlaying ? "Pause" : "Play"
    };
  }, [isPlaying, hasEnded]);
  const formattedTimeInfo = useMemo(() => {
    function formatTime(timeInSeconds) {
      let minutes = Math.floor(timeInSeconds / 60);
      minutes = minutes >= 10 ? minutes : "0" + minutes;
      let seconds = Math.floor(timeInSeconds % 60);
      seconds = seconds >= 10 ? seconds : "0" + seconds;
      return minutes + ":" + seconds;
    }
    return {
      elapsed: formatTime(secondsPlayed),
      total: formatTime(durationInSeconds || 0)
    };
  }, [durationInSeconds, secondsPlayed]);
  const getMediaElement = () => document.querySelector(`#${reactPlayerId} audio`) ?? document.querySelector(`#${reactPlayerId} video`);
  const handleReplay = useCallback(() => {
    var _a;
    setHasEnded(false);
    mediaPlayer.current.seekTo(0);
    setIsPlaying(true);
    return (_a = getMediaElement()) == null ? void 0 : _a.play();
  }, []);
  const handlePlaybackClick = useCallback(() => {
    if (!mediaUrl) {
      return setIsLoading(true);
    }
    if (hasEnded) {
      handleReplay();
    }
    setError("");
    setIsPlaying(!isPlaying);
  }, [mediaUrl, hasEnded, isPlaying]);
  return {
    isLoading,
    error,
    setError,
    secondsPlayed,
    setSecondsPlayed,
    durationInSeconds,
    setDurationInSeconds,
    playbackImgInfo,
    handlePlaybackClick,
    isPlaying,
    setIsPlaying,
    mediaPlayer,
    isMuted,
    formattedTimeInfo,
    mediaUrl,
    hasEnded,
    setHasEnded,
    reactPlayerId
  };
};
const defaultDateTimeFormatOptions = {
  date: { day: "2-digit", month: "2-digit", year: "numeric" },
  longDate: { day: "2-digit", month: "long", year: "numeric", weekday: "long" },
  shortMonth: { day: "2-digit", month: "short", weekday: "long" },
  shortMonthYear: { day: "2-digit", month: "short", year: "numeric", weekday: "short" },
  time: { hour: "numeric", minute: "numeric" },
  timeWithSeconds: { hour: "numeric", minute: "numeric", second: "numeric" },
  shortDay: { weekday: "short" },
  dayMonth: { day: "numeric", month: "short" }
};
const format = (date, options, locale) => {
  const {
    date: renderDate = true,
    time: renderTime = false,
    seconds: renderSeconds = false,
    longWordDate: renderLongDate = false,
    shortMonth: renderShortMonth = false,
    shortMonthYear: renderShortMonthYear = false,
    shortDay: renderShortDay = false,
    dayMonth: renderDayMonth = false,
    ...rest
  } = options ?? {};
  const resolvedOptions = {};
  if (renderDate)
    lodashExports.merge(
      resolvedOptions,
      renderLongDate ? defaultDateTimeFormatOptions.longDate : defaultDateTimeFormatOptions.date
    );
  if (renderShortMonth)
    lodashExports.merge(resolvedOptions, defaultDateTimeFormatOptions.shortMonth);
  if (renderShortMonthYear)
    lodashExports.merge(resolvedOptions, defaultDateTimeFormatOptions.shortMonthYear);
  if (renderTime)
    lodashExports.merge(
      resolvedOptions,
      renderSeconds ? defaultDateTimeFormatOptions.timeWithSeconds : defaultDateTimeFormatOptions.time
    );
  if (renderShortDay)
    lodashExports.merge(resolvedOptions, defaultDateTimeFormatOptions.shortDay);
  if (renderDayMonth)
    lodashExports.merge(resolvedOptions, defaultDateTimeFormatOptions.dayMonth);
  if (rest)
    lodashExports.merge(resolvedOptions, rest);
  const intlDate = new Intl.DateTimeFormat(locale ?? navigator.language, resolvedOptions);
  return intlDate.format(date);
};
export {
  format as f,
  useMediaPlayback as u
};
