import { jsx, jsxs, Fragment as Fragment$1 } from "react/jsx-runtime";
import classNames from "classnames";
import { useState, useEffect, useMemo, Fragment } from "react";
import Button from "./Button.mjs";
import Form from "./Form.mjs";
import Tooltip from "./Tooltip.mjs";
import { S as SUBMIT_KEYS } from "./index-BBDie59p.mjs";
const matchFloatInString = /[+-]?\d+(\.\d+)?/g;
const DownArrow = "data:image/svg+xml,%3csvg%20width='10'%20height='6'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath%20d='M4.608%204.604a.611.611%200%2001-.438-.181L.844%201.096A.617.617%200%2001.84.233a.608.608%200%2001.864.003L4.649%203.18%207.64.294A.617.617%200%20018.5.307a.608.608%200%2001-.017.863L5.1%204.437a.612.612%200%2001-.492.167z'%20id='a'/%3e%3c/defs%3e%3cg%20transform='translate(.333%201)'%20fill='none'%20fill-rule='evenodd'%3e%3cmask%20id='b'%20fill='%23fff'%3e%3cuse%20xlink:href='%23a'/%3e%3c/mask%3e%3cg%20mask='url(%23b)'%20fill='%2300856f'%3e%3cpath%20d='M-3.333-6h16v16h-16z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
const LeftArrow = "data:image/svg+xml,%3csvg%20viewBox='0%200%206%2010'%20xmlns='http://www.w3.org/2000/svg'%20fill-rule='evenodd'%20clip-rule='evenodd'%20stroke-linejoin='round'%20stroke-miterlimit='1.414'%3e%3cpath%20d='M.008%204.895a.743.743%200%2001.238-.512L4.624.231A.823.823%200%20015.2%200c.44%200%20.8.342.8.759a.742.742%200%2001-.244.547L1.882%204.98l3.799%203.73a.741.741%200%2001.224.53c0%20.416-.361.76-.8.76a.823.823%200%2001-.577-.233l-4.3-4.224a.739.739%200%2001-.219-.65z'%20fill='%23565656'/%3e%3c/svg%3e";
const RightArrow = "data:image/svg+xml,%3csvg%20viewBox='0%200%206%2010'%20xmlns='http://www.w3.org/2000/svg'%20fill-rule='evenodd'%20clip-rule='evenodd'%20stroke-linejoin='round'%20stroke-miterlimit='1.414'%3e%3cpath%20d='M5.992%204.895a.743.743%200%2000-.238-.512L1.376.231A.823.823%200%2000.8%200c-.44%200-.8.342-.8.759%200%20.206.088.403.244.547L4.118%204.98.32%208.711a.741.741%200%2000-.224.53c0%20.416.361.76.8.76a.823.823%200%2000.577-.233l4.3-4.224a.739.739%200%2000.219-.65z'%20fill='%23565656'/%3e%3c/svg%3e";
const UpArrow = "data:image/svg+xml,%3csvg%20width='10px'%20height='6px'%20viewBox='0%200%2010%206'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2051.3%20(57544)%20-%20http://www.bohemiancoding.com/sketch%20--%3e%3ctitle%3eGreenArrow%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M4.60831001,4.60351976%20C4.45072518,4.60478372%204.29220393,4.5447738%204.16995366,4.42252353%20L0.843879528,1.0964494%20C0.604854194,0.857424064%200.610723021,0.4640186%200.841461532,0.233280089%20C1.07915144,-0.00440982257%201.46283628,-0.00609647952%201.70463084,0.235698085%20L4.64931953,3.18038678%20L7.63822533,0.294034001%20C7.88138583,0.0592166388%208.27462895,0.071950444%208.50130538,0.306680755%20C8.73481082,0.548482726%208.729801,0.932138558%208.48382337,1.1696764%20L5.10020782,4.43719595%20C4.96460967,4.56814156%204.78234001,4.62210566%204.60831001,4.60351976%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Symbols'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cg%20transform='rotate(180)'%20transform-origin='center'%3e%3cg%20id='Component-/-Settings-/-Filter'%20transform='translate(-166.000000,%20-5.000000)'%3e%3cg%20id='Graphic-/-Icon-/-Arrow-/-Down-/-Default'%20transform='translate(163.000000,%200.000000)'%3e%3cg%20id='Icon'%20transform='translate(3.333333,%206.000000)'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cg%20id='Colour-/-CircleLoop-/-Teal'%20mask='url(%23mask-2)'%20fill='%2300856f'%3e%3cg%20transform='translate(-3.333333,%20-6.000000)'%20id='Colour'%3e%3crect%20x='0'%20y='0'%20width='16'%20height='16'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
const Table = ({
  rowsPerPage = 1e3,
  data = [],
  cols = [],
  sortBy = "ascending",
  totalRowCount,
  handleCurrentPageChange,
  shouldUseCustomPagination,
  getAriaLabel,
  ariaRowDescription,
  ...props
}) => {
  const [sortState, setSortState] = useState(props.sort || "");
  const [ascending, setAscending] = useState(sortBy === "ascending");
  const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
  const [pageInput, setPageInput] = useState("1");
  useEffect(() => {
    if (handleCurrentPageChange)
      handleCurrentPageChange(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (props.pagination || props.resetPagination)
      setCurrentPage(props.currentPage || 1);
  }, [props.pagination, props.resetPagination]);
  const isEmptyValue = (val) => {
    return val === void 0 || val === null || val === "";
  };
  const getValue = (obj, name) => {
    if (name === "*")
      return obj;
    const parts = name.split(".");
    if (parts.length === 1)
      return obj[parts[0]];
    if (!obj[parts[0]])
      return null;
    return getValue(obj[parts[0]], parts.slice(1).join("."));
  };
  const formatSortValue = (value, col) => {
    var _a;
    if (col == null ? void 0 : col.isDate)
      return new Date(value).getTime();
    if (typeof value === "string") {
      if (col == null ? void 0 : col.isNumber)
        return parseFloat(((_a = value.match(matchFloatInString)) == null ? void 0 : _a[0]) ?? "");
      return value.toLowerCase();
    }
    if (value === void 0)
      return col == null ? void 0 : col.defaultValue;
    return value;
  };
  const getStatusPriority = (status) => {
    switch (status) {
      case "pass":
        return 1;
      case "in_progress":
        return 2;
      case "fail":
        return 3;
      default:
        return 0;
    }
  };
  const handleEmptyValues = (aValue, bValue) => {
    if (isEmptyValue(aValue) && isEmptyValue(bValue)) {
      return 0;
    } else if (isEmptyValue(aValue)) {
      return 1;
    } else if (isEmptyValue(bValue)) {
      return -1;
    } else {
      return null;
    }
  };
  const sortColStatus = (aValue, bValue, aValue2, bValue2) => {
    const statusComparison = getStatusPriority(aValue) - getStatusPriority(bValue);
    if (statusComparison === 0) {
      return aValue2 < bValue2 ? 1 : -1;
    } else {
      return statusComparison > 0 ? 1 : -1;
    }
  };
  const tableSort = useMemo(() => {
    if (props.noSort)
      return data;
    if (!data)
      return [];
    const col = cols.find(
      (c) => c.name === sortState || c.sortField === sortState
    ) || {
      isDate: false,
      defaultValue: void 0,
      isNumber: false
    };
    return [...data].sort((a, b) => {
      const aValue = formatSortValue(getValue(a, sortState), col);
      const bValue = formatSortValue(getValue(b, sortState), col);
      const emptyValueSort = handleEmptyValues(aValue, bValue);
      if (emptyValueSort !== null)
        return emptyValueSort;
      let sort;
      if (sortState === "status") {
        const aValue2 = formatSortValue(getValue(a, "scorePercentage"), col);
        const bValue2 = formatSortValue(getValue(b, "scorePercentage"), col);
        sort = sortColStatus(aValue, bValue, aValue2, bValue2);
      } else {
        sort = aValue > bValue ? 1 : -1;
      }
      return ascending ? sort : sort * -1;
    });
  }, [data, sortState, ascending, props.noSort]);
  const onSort = (name) => {
    if (sortState === name)
      setAscending(!ascending);
    else {
      setAscending(!ascending);
      setSortState(name);
    }
  };
  const isSorted = (name) => sortState === name;
  const getNumberOfPages = () => {
    if (!data)
      return 1;
    const totalRows = totalRowCount || data.length;
    const pagesCount = Math.ceil(totalRows / rowsPerPage);
    return pagesCount || 1;
  };
  useEffect(() => {
    const numberOfPages = getNumberOfPages();
    if (currentPage < numberOfPages)
      return;
    setCurrentPage(numberOfPages);
  }, [data.length]);
  const onPrevPage = () => {
    if (currentPage > 1)
      setCurrentPage(currentPage - 1);
  };
  const onNextPage = () => {
    if (currentPage < getNumberOfPages())
      setCurrentPage(currentPage + 1);
  };
  const onPaginationSubmit = () => {
    if (!pageInput)
      return;
    setCurrentPage(parseInt(pageInput.toString()));
  };
  const onPaginationChange = (v) => {
    const val = v.target.value;
    if (!val) {
      setPageInput("");
      return;
    }
    if (!/^[0-9]*$/g.test(val))
      return;
    const parsed = parseInt(val);
    if (!isNaN(parsed)) {
      if (parsed > 0 && parsed <= getNumberOfPages())
        setPageInput(val);
      else
        return;
    }
  };
  const arrow = (dir, n = 1) => Array(n).fill(null).map((_, i) => /* @__PURE__ */ jsx(
    "img",
    {
      src: dir === "l" ? LeftArrow : RightArrow,
      height: 8,
      alt: `${dir === "l" ? "Left" : "Right"} arrow`
    },
    i
  ));
  const handleKeyDown = (e, callback, id) => {
    var _a;
    if (SUBMIT_KEYS.has(e.key) && ((_a = document.activeElement) == null ? void 0 : _a.id) == id) {
      callback();
    }
  };
  const handleColumnClick = (col, colFieldName) => {
    const columnName = col.sortField || colFieldName;
    if (!col.sort)
      return;
    onSort(columnName);
    props.handleColumnClick && props.handleColumnClick(columnName, ascending);
  };
  const handleRowClick = (row, i) => {
    var _a;
    if (props.selectable) {
      (_a = props.onSelect) == null ? void 0 : _a.call(props, row, i);
    }
  };
  const ariaSort = (name, ascending2) => {
    if (isSorted(name)) {
      return ascending2 ? "ascending" : "descending";
    } else {
      return "none";
    }
  };
  const generateAriaDescription = (row) => {
    const segments = cols.map((col) => {
      if (col.getAriaDescription === null)
        return "";
      let value = getValue(row, col.value || col.path || col.name) ?? col.defaultValue;
      if (col.getAriaDescription)
        value = col.getAriaDescription(value);
      if (value === null)
        return "";
      if (isInvalidValue(value))
        value = "No Value";
      return `${col.name}: ${value}`;
    }).filter(Boolean);
    if (ariaRowDescription)
      segments.push(ariaRowDescription);
    return segments.join(", ");
  };
  const isInvalidValue = (value) => {
    return ["object", "undefined"].includes(typeof value) || value === "";
  };
  const renderTableHeader = () => {
    return /* @__PURE__ */ jsx("thead", { children: /* @__PURE__ */ jsx("tr", { children: cols.map((col, index) => {
      const colClasses = classNames({
        "sa-has-sort": col.sort,
        "sa-col-center": col.position === "center",
        "sa-col-right": col.position === "right",
        [col.className || ""]: !!col.className
      });
      const colFieldName = col.value || col.path || col.name;
      return /* @__PURE__ */ jsx(Tooltip, { text: col.tooltip, children: /* @__PURE__ */ jsxs(
        "th",
        {
          "aria-description": `Use enter to toggle alphabetical sorting direction.`,
          "aria-sort": ariaSort(col.sortField ?? colFieldName, ascending),
          className: colClasses,
          id: "table-header",
          tabIndex: 0,
          onClick: () => handleColumnClick(col, colFieldName),
          onKeyDown: (e) => handleKeyDown(
            e,
            () => handleColumnClick(col, colFieldName),
            "table-header"
          ),
          "aria-describedby": "sa-tooltip",
          children: [
            /* @__PURE__ */ jsx("span", { id: "sa-tooltip", children: col.renderTitle ? col.renderTitle(col.name, col.data) : col.name }),
            isSorted(col.sortField || colFieldName) ? /* @__PURE__ */ jsx("span", { className: "sa-sort-arrow", children: /* @__PURE__ */ jsx(
              "img",
              {
                "aria-hidden": "true",
                alt: "sorted by",
                src: ascending ? UpArrow : DownArrow
              }
            ) }) : null
          ]
        }
      ) }, col.key || colFieldName);
    }) }) });
  };
  const min = shouldUseCustomPagination ? 0 : (currentPage - 1) * rowsPerPage;
  const max = rowsPerPage + (min - 1);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs("div", { className: "sa-table-wrapper", children: [
    data.length !== 0 ? /* @__PURE__ */ jsxs(
      "table",
      {
        className: classNames({
          "sa-table": true,
          [props.className]: !!props.className,
          "sa-fixed-cols": props.fixedCols
        }),
        children: [
          props.caption ? /* @__PURE__ */ jsx("caption", { className: "accessibility-hidden", children: props.caption }) : null,
          props.noHeader ? null : renderTableHeader(),
          /* @__PURE__ */ jsx("tbody", { children: tableSort.filter((row) => !!row).map((row, i1) => {
            var _a;
            const rowSelected = (_a = props.isRowSelected) == null ? void 0 : _a.call(props, row, i1);
            const uniqueID = `${row.ID || row[props.columnid]}-${i1}`;
            const rowClass = classNames({
              "sa-row-select": props.selectable,
              "sa-row-selected": rowSelected,
              [row.className]: !!row.className
            });
            return /* @__PURE__ */ jsx(
              "tr",
              {
                id: uniqueID,
                tabIndex: props.selectable ? 0 : -1,
                "aria-label": getAriaLabel == null ? void 0 : getAriaLabel(row, i1),
                "aria-description": generateAriaDescription(row),
                "aria-selected": rowSelected,
                className: rowClass,
                onClick: () => handleRowClick(row, i1),
                onKeyDown: (e) => handleKeyDown(
                  e,
                  () => handleRowClick(row, i1),
                  uniqueID
                ),
                children: cols.map((col, i2) => {
                  const colClasses = classNames({
                    "sa-row-title": col.title,
                    "sa-col-center": col.position === "center",
                    "sa-col-right": col.position === "right",
                    "sa-col-fixed-width": col.fixedWidth,
                    [col.className || ""]: !!col.className
                  });
                  const value = getValue(row, col.value || col.path || col.name) ?? col.defaultValue;
                  const renderedValue = col.render ? col.render(value) : value;
                  return /* @__PURE__ */ jsx(
                    "td",
                    {
                      className: colClasses,
                      children: col.clipFieldValues ? /* @__PURE__ */ jsx(Tooltip, { text: renderedValue, children: /* @__PURE__ */ jsx(
                        "div",
                        {
                          className: "sa-col-clipped-width-field",
                          "data-testid": "clipped-field",
                          children: renderedValue
                        }
                      ) }) : /* @__PURE__ */ jsx(Fragment$1, { children: renderedValue })
                    },
                    `${col.name}-${i2}`
                  );
                })
              },
              uniqueID
            );
          }).slice(min, max + 1) }),
          props.footer ? /* @__PURE__ */ jsx("tfoot", { children: props.footer }) : null
        ]
      }
    ) : props.noDataShowHeaders ? /* @__PURE__ */ jsxs("div", { children: [
      /* @__PURE__ */ jsxs(
        "table",
        {
          className: classNames({
            "sa-table": true,
            [props.className]: !!props.className,
            "sa-fixed-cols": props.fixedCols
          }),
          children: [
            props.caption ? /* @__PURE__ */ jsx("caption", { className: "accessibility-hidden", children: props.caption }) : null,
            renderTableHeader()
          ]
        }
      ),
      /* @__PURE__ */ jsx("div", { className: "sa-table no-data", children: /* @__PURE__ */ jsx("div", { className: "sa-no-table-data", children: props.noData === void 0 ? "No data available" : props.noData }) })
    ] }) : props.noData !== null && /* @__PURE__ */ jsx("div", { className: "sa-table no-data", children: /* @__PURE__ */ jsx("div", { className: "sa-no-table-data", children: props.noData === void 0 ? "No data available" : props.noData }) }),
    props.pagination && getNumberOfPages() > 1 && /* @__PURE__ */ jsxs("div", { className: "sa-pagination", children: [
      /* @__PURE__ */ jsxs("div", { className: "sa-pagination-relative", children: [
        /* @__PURE__ */ jsx(Button, { type: "button", title: "First page", onClick: () => setCurrentPage(1), children: arrow("l", 2) }),
        /* @__PURE__ */ jsx(Button, { type: "button", title: "Previous page", onClick: onPrevPage, children: arrow("l") }),
        /* @__PURE__ */ jsx("div", { children: `${currentPage} of ${getNumberOfPages()}` }),
        /* @__PURE__ */ jsx(Button, { type: "button", title: "Next page", onClick: onNextPage, children: arrow("r") }),
        /* @__PURE__ */ jsx(
          Button,
          {
            type: "button",
            title: "Last page",
            onClick: () => setCurrentPage(getNumberOfPages()),
            children: arrow("r", 2)
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(
        Form,
        {
          "aria-label": "Change the current page number",
          onSubmit: onPaginationSubmit,
          className: "sa-pagination-absolute",
          children: [
            /* @__PURE__ */ jsx("label", { htmlFor: "pageNumber", children: "Go to page" }),
            /* @__PURE__ */ jsx(
              "input",
              {
                type: "text",
                id: "pageNumber",
                name: "pageNumber",
                className: "sa-pagination-absolute-selector",
                value: pageInput.toString(),
                style: { width: `${pageInput.toString().length}ch` },
                onChange: onPaginationChange
              }
            ),
            /* @__PURE__ */ jsx(
              Button,
              {
                disabled: !pageInput,
                title: "Go to page",
                className: "sa-pagination-absolute-go",
                children: "Go"
              }
            )
          ]
        }
      )
    ] })
  ] }) });
};
export {
  Table as default
};
