import { TimeElapsed } from '@missionlabs/smartagent-app-components'
import { default as classnames } from 'classnames'
import { ReactComponent as ClockIcon } from 'images/icon-clock-small.svg'
import { ReactComponent as LastSeenIcon } from 'images/icon-lastseen-small.svg'

import styles from './ContactInfo.module.scss'

interface Props {
    contactTimeStarted: number
    timeFromLastMessage?: Date
    isSelected: boolean
    acw: boolean
}

const ContactInfo = ({
    contactTimeStarted,
    timeFromLastMessage,
    isSelected,
    acw,
}: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <div className={styles.infoInner}>
                    <p className={`${styles.indicator}`}>
                        {isSelected || acw ? (
                            <ClockIcon className={styles['indicator--selected']} />
                        ) : (
                            <ClockIcon />
                        )}

                        <TimeElapsed format="mm:ss" minutes date={new Date(contactTimeStarted)} />
                    </p>

                    {timeFromLastMessage && (
                        <p className={classnames(styles.indicator)}>
                            {isSelected || acw ? (
                                <LastSeenIcon className={styles['indicator--selected']} />
                            ) : (
                                <LastSeenIcon />
                            )}

                            <TimeElapsed format="mm:ss" minutes date={timeFromLastMessage} />
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
