import { Dropdown } from '@missionlabs/smartagent-app-components'
import * as React from 'react'
import { H } from 'react-accessible-headings'
import { Controls } from './Controls'
import DownloadButton from './DownloadButton'
import { VideoControls } from './VideoControls'
import { PlaybackRateOption } from './interfaces/AudioPlayer'
import { IHeaderProps } from './interfaces/Header'

const playbackRateOptions: PlaybackRateOption[] = [
    { label: 'Speed: 0.5x', data: '0.5' },
    { label: 'Speed: 1x', data: '1.0' },
    { label: 'Speed: 2x', data: '2.0' },
    { label: 'Speed: 3x', data: '3.0' },
]
// default 1x
const defaultSelectedPlaybackOption = playbackRateOptions[1]

const Header: React.FunctionComponent<IHeaderProps> = ({
    screenRecordingURL,
    canDownload,
    contactID,
    recordingDate,
    signedUrl,
    title,
    fullScreen,
    refs,
    playbackState,
    videoZoomControls,
}) => {
    const [currentPlaybackOption, setCurrentPlaybackOption] = React.useState(
        defaultSelectedPlaybackOption,
    )
    const { audioRef, videoRef, containerRef } = refs
    const { elapsed, playing, setPlaying, setPersistentElapsed, duration, setElapsed } =
        playbackState
    const { isFullScreen, toggleFullScreen } = fullScreen

    const playbackRateOnChange = (option: PlaybackRateOption) => {
        setCurrentPlaybackOption(option)
        if (audioRef?.current) audioRef.current.playbackRate = parseFloat(option.data)
        if (videoRef?.current) videoRef.current.playbackRate = parseFloat(option.data)
    }

    const playPause = () => {
        setPlaying(!playing)
    }

    return (
        <div className="row middle evenly grow">
            <H>{title}</H>
            {screenRecordingURL ? (
                <VideoControls
                    fullScreen={{ isFullScreen, toggleFullScreen }}
                    setPersistentElapsed={setPersistentElapsed}
                    audio={audioRef}
                    containerRef={containerRef}
                    elapsed={elapsed}
                    setElapsed={setElapsed}
                    isPlaying={playing}
                    total={duration}
                    playPause={playPause}
                    videoRef={videoRef}
                    totalDuration={duration}
                    videoZoomControls={videoZoomControls}
                    playback={{
                        playbackRateOptions,
                        playbackRateOnChange,
                        currentPlaybackOption,
                    }}
                />
            ) : (
                <>
                    <Controls
                        elapsed={elapsed}
                        isPlaying={playing}
                        total={duration}
                        playPause={playPause}
                    />

                    <Dropdown
                        options={playbackRateOptions}
                        onChange={playbackRateOnChange}
                        value={currentPlaybackOption?.label || ''}
                    />

                    <DownloadButton
                        canDownload={canDownload}
                        contactID={contactID}
                        recordingDate={recordingDate}
                        signedUrl={signedUrl}
                    />
                </>
            )}
        </div>
    )
}

export default Header
