import axios from 'axios'
import classNames from 'classnames'
import { Loader, Notification, RouteWithPermissionsCheck } from 'components'
import { AccessibleAlert } from 'components/AccessibleAlert'
import ProcessModalDraggable from 'components/ProcessModalDraggable'
import loadExternalDirectoryContacts from 'hooks/redux/loadExternalDirectoryContacts'
import loadTeamsContacts from 'hooks/redux/loadTeamsContacts'
import useCall from 'hooks/redux/useCall'
import useChatConnections from 'hooks/redux/useChatConnections'
import useNotifications from 'hooks/redux/useNotifications'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { CustomAxiosConfig } from 'services/api'
import { setSelectedChat } from 'store/chat/chat.actions'
import { clearNotification } from 'store/notification/notification.actions'
import RootState from 'store/state'
import { setCSSVar } from 'utils'
import ChatRoutes from 'views/Chat/chat-routes'
import SystemMessages from 'views/Header/SystemMessages'
import Home from 'views/Home'
import Tasks from 'views/Tasks'
import WebHIDNotificationBanner from 'views/WebHID/WebHIDNotification'
import Header from '../Header'
import './main.scss'

interface Props {}

const Main: React.FC<Props> = (props) => {
    const [classes, setClasses] = useState('')

    const { pathname } = useLocation()
    const history = useHistory()

    const dispatch = useDispatch()

    const call = useCall()
    const chat = useChatConnections()
    const tasks = useTaskConnections()
    const hasFeature = useHasFeature()
    const agentMonitoring = useSelector((state: RootState) => state.metrics.monitoringAgent)

    const unreadScoresCount = useSelector((state: RootState) => state.qa.unreadScoresCount) || 0
    const showScoresAlert = hasFeature(AppFeatures.QA) && unreadScoresCount > 0
    const showCapacityCard = hasFeature(AppFeatures.AGENT_CAPACITY_CARD_DISPLAY)

    const chatIncoming = useChatConnections('incoming')
    const chatSelected = useChatConnections('selected')
    const taskIncoming = useTaskConnections('incoming')

    const currentStatus = useSelector(
        (state: RootState) => state.user?.status.name,
    ) as connect.AgentAvailStates

    const showIncoming = () =>
        !agentMonitoring && (!!call?.incoming || !!chatIncoming || !!taskIncoming)

    const isHeaderCollapsed = () => {
        if (
            !(
                chat.length ||
                tasks.length ||
                call ||
                [
                    connect.AgentAvailStates.AFTER_CALL_WORK, 
                    connect.AgentErrorStates.MISSED_CALL_AGENT,
                    connect.AgentErrorStates.FAILED_CONNECT_AGENT,
                ].includes(currentStatus) ||
                showIncoming() ||
                showCapacityCard
            )
        ) {
            setCSSVar('--sa-header-height', '40px')
            return true
        }

        setCSSVar('--sa-header-height', '80px')
        return false
    }

    const isUserInCall = call?.inCall

    useEffect(() => {
        if (
            (isUserInCall && pathname !== '/' && !agentMonitoring) ||
            (agentMonitoring && !agentMonitoring.error)
        ) {
            history.push(`/${window.location.search}`)
        }
    }, [isUserInCall])

    useEffect(() => {
        if (chatSelected) return

        if (pathname === '/chat' && chatIncoming?.status === 'connecting' && !chatSelected) {
            dispatch(setSelectedChat(chatIncoming.id))
        }
    }, [chatSelected, chatIncoming, pathname, dispatch])

    const token = useSelector<RootState, string>((state) => state.auth?.token!)
    const companyID = useSelector<RootState, string>((state) => state.app.ID!)
    const instanceID = useSelector<RootState, string>((state) => state.app.instance?.ID!)
    const isQueuedTasksProcessingModalShow = useSelector<RootState, boolean>(
        (state) => state.queuedTasks.queuedTasksProcessingShowModal,
    )

    const attachToken = useCallback(
        async (config: CustomAxiosConfig) => {
            //Check for token and is a smartagent api call
            if (!token || !config.baseURL?.includes('smartagent.app')) return config

            if (config.ignoreToken) return config

            config.headers = {
                ...config.headers,
                'X-Amz-Security-Token': token,
            }
            config.url = config.url
                ?.replace('{companyID}', companyID)
                .replace('{instanceID}', instanceID)
            return config
        },
        [token, companyID, instanceID],
    )

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(attachToken)
        return () => axios.interceptors.request.eject(interceptor)
    }, [attachToken])

    loadExternalDirectoryContacts()

    loadTeamsContacts()

    const notification = useNotifications()

    const RealTimeMetrics = useMemo(() => lazy(() => import('views/Metrics/RealTimeMetrics')), [])
    const HistoricMetrics = useMemo(() => lazy(() => import('views/Metrics/HistoricMetrics')), [])
    const NavPlugin = useMemo(() => lazy(() => import('views/Menu/NavPlugin')), [])
    const QA = useMemo(() => lazy(() => import('views/QA')), [])
    const Settings = useMemo(() => lazy(() => import('views/Settings')), [])
    const AdminSettings = useMemo(() => lazy(() => import('views/AdminSettings')), [])
    const Case = useMemo(() => lazy(() => import('views/Case')), [])
    const CampaignManagement = useMemo(() => lazy(() => import('views/CampaignManagement')), [])
    const QueuedTasks = useMemo(() => lazy(() => import('views/Metrics/QueuedTasks')), [])
    const AutomatedRules = useMemo(() => lazy(() => import('views/Metrics/AutomatedRules')), [])

    return (
        <main
            className={classNames({
                'header-collapse': isHeaderCollapsed(),
                [classes]: !!classes,
            })}
        >
            <AccessibleAlert
                hidden={!showScoresAlert}
                text="New evaluation has been received, find details on my scores page"
            />
            <SystemMessages onSetClasses={(classes: string) => setClasses(classes)} />
            <WebHIDNotificationBanner />

            <Header pathname={pathname} showIncoming={showIncoming()} />

            <Suspense
                fallback={
                    <div className="lazy-loading">
                        <Loader />
                    </div>
                }
            >
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <RouteWithPermissionsCheck
                        path="/chat"
                        component={ChatRoutes}
                        appFeature={AppFeatures.CHAT}
                    />
                    <RouteWithPermissionsCheck
                        path="/metrics/real-time"
                        component={RealTimeMetrics}
                        appFeature={AppFeatures.REALTIME_METRICS}
                    />
                    <RouteWithPermissionsCheck
                        path="/metrics/historic"
                        component={HistoricMetrics}
                        appFeature={AppFeatures.HISTORIC_METRICS}
                    />
                    <RouteWithPermissionsCheck
                        path="/metrics/queued-tasks"
                        component={QueuedTasks}
                        appFeature={AppFeatures.QUEUED_TASKS}
                    />
                    <RouteWithPermissionsCheck
                        path="/metrics/automated-rules"
                        component={AutomatedRules}
                        appFeature={AppFeatures.AUTOMATED_RULES}
                    />
                    <Route path="/metrics/:plugin" component={NavPlugin} />
                    <RouteWithPermissionsCheck
                        path="/qa"
                        component={QA}
                        appFeature={AppFeatures.QA}
                    />
                    <Route path="/admin-settings" component={AdminSettings} />
                    <Route path="/settings" component={Settings} />
                    {hasFeature(AppFeatures.CASE) && (
                        <Route path="/case/:caseID" component={Case} />
                    )}
                    {hasFeature(AppFeatures.CASE) && <Route path="/case" component={Case} />}
                    {hasFeature(AppFeatures.CAMPAIGN_MANAGEMENT) && (
                        <Route path="/campaignmanagement" component={CampaignManagement} />
                    )}
                    <Route path="/tasks" component={Tasks} />
                    <RouteWithPermissionsCheck
                        path="/task"
                        component={Tasks}
                        appFeature={AppFeatures.TASK_HISTORY}
                    />
                </Switch>
            </Suspense>
            {notification && (
                <Notification
                    header={notification.header}
                    text={notification.text}
                    type={notification.type}
                    closeAfterMs={notification.closeAfterMs}
                    onClose={() => dispatch(clearNotification())}
                />
            )}

            {isQueuedTasksProcessingModalShow && <ProcessModalDraggable />}
        </main>
    )
}

export default React.memo(Main)
