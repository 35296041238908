import axios, { AxiosError } from 'axios'
import React, { PureComponent } from 'react'
import { IContactContext } from 'widgets/contexts/contact'
import AudioController from './AudioController'

export interface IStateFns {
    setCh1Muted(muted: boolean): void
    setCh2Muted(muted: boolean): void
    setPlaying(playing: boolean): void
    setElapsed(elapsed: number): void
}

interface Props {
    url: string
    time?: number
    context: IContactContext
    stateFns: IStateFns
    onError: (err: AudioErrorWithStatusCode) => void
    setAudioRef: React.Dispatch<React.SetStateAction<any>>
}

export interface AudioErrorWithStatusCode extends Event {
    statusCode?: number
}

export class Audio extends PureComponent<Props> {
    // Initialize ref
    public audioRef = React.createRef<HTMLAudioElement>()

    private audioController: AudioController = new AudioController(
        this.props.stateFns,
        this.props.context.updateSeekProgress,
    )

    componentDidMount() {
        this.audioController.init(this.audioRef.current!)
        this.audioRef.current!.onerror = this.audioError

        if (this.audioRef.current) {
            this.props.setAudioRef(this.audioRef)
        }
    }

    private audioError = async (eventOrErrorString: string | Event): Promise<void> => {
        this.audioController.pause()
        const error = (
            typeof eventOrErrorString === 'string'
                ? new Event(eventOrErrorString)
                : eventOrErrorString
        ) as AudioErrorWithStatusCode
        //No way to detect what type of error.
        //We manually GET and attach any error status code before we bubble up
        try {
            await axios.get(this.props.url)
        } catch (err) {
            const axiosError = err as AxiosError
            error.statusCode = axiosError.response?.status
        } finally {
            this.props.onError?.(error)
        }
    }

    public muteChannel = (ch: number) => {
        if (ch === 1) this.audioController.disconnectLeft()
        if (ch === 2) this.audioController.disconnectRight()
    }

    public unmuteChannel = (ch: number) => {
        if (ch === 1) this.audioController.connectLeft()
        if (ch === 2) this.audioController.connectRight()
    }

    public seek = (to: number) => {
        const { time } = this.props
        const duration = time ?? this.getDuration()
        if (!this.audioRef.current) return

        this.audioController.seek(to, duration)
    }

    public playPause = async () => {
        const audio = this.audioRef.current
        if (!audio) return
        if (audio.paused) {
            await this.audioController.play(this.props.time ?? this.getDuration())
            return true
        }

        this.audioController.pause()
        return false
    }

    public getDuration = () => this.audioRef.current?.duration ?? 0
    public getElapsed = () => this.audioRef.current!.currentTime
    public setPlaybackRate = (selectedSpeed: number) =>
        this.audioController.setAudioPlaybackRate(selectedSpeed)

    public render() {
        const { url } = this.props
        // eslint-disable-next-line jsx-a11y/media-has-caption
        return <audio crossOrigin="anonymous" src={url} ref={this.audioRef} />
    }
}
