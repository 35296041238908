import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import useCall from 'hooks/redux/useCall'
import useSettings from 'hooks/redux/useSettings'
import useUser from 'hooks/redux/useUser'
import { getRoutingProfiles } from 'store/settings/settings.actions'

import useChatConnections from 'hooks/redux/useChatConnections'
import useMissedContact from 'hooks/redux/useMissedContact'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import ChatUnavailableIcon from 'images/icon-channel-chat-unavailable.svg'
import ChatAvailableIcon from 'images/icon-channel-chat.svg'
import TaskUnavailableIcon from 'images/icon-channel-task-unavailable.svg'
import TaskAvailableIcon from 'images/icon-channel-task.svg'
import VoiceUnavailableIcon from 'images/icon-channel-voice-unavailable.svg'
import VoiceAvailableIcon from 'images/icon-channel-voice.svg'
import EmailUnavailableIcon from 'images/icon-channel-email-unavailable.svg'
import EmailAvailableIcon from 'images/icon-channel-email.svg'
import styles from './CapacityCard.module.scss'
import {
    getMediaConcurrencyHash,
    getNonRoutableStates,
    getRoutingProfile,
    IMediaConcurrency,
} from './utils'

interface Channel {
    available: string
    unavailable: string
}

enum ChannelsEmail {
    EMAIL = "EMAIL",
}

type ChannelType = connect.ChannelType | ChannelsEmail

const channelIcons: Record<ChannelType, Channel> = {
    [connect.ChannelType.VOICE]: {
        available: VoiceAvailableIcon,
        unavailable: VoiceUnavailableIcon,
    },
    [connect.ChannelType.CHAT]: {
        available: ChatAvailableIcon,
        unavailable: ChatUnavailableIcon,
    },
    [connect.ChannelType.TASK]: {
        available: TaskAvailableIcon,
        unavailable: TaskUnavailableIcon,
    },
    [ChannelsEmail.EMAIL]: {
        available: EmailAvailableIcon,
        unavailable: EmailUnavailableIcon,
    },
}

const CapacityCard: React.FC = () => {
    const dispatch = useDispatch()
    const user = useUser()
    const call = useCall()
    const chats = useChatConnections('current')
    const tasks = useTaskConnections('current')

    const inCall = call?.inCall ?? user?.status.name === connect.AgentAvailStates.AFTER_CALL_WORK

    const contactsSum = useMemo(
        () => ({
            [connect.ChannelType.CHAT]: chats?.length ?? 0,
            [connect.ChannelType.TASK]: tasks?.length ?? 0,
            [connect.ChannelType.VOICE]: inCall ? 1 : 0,
        }),
        [chats, tasks, inCall],
    )

    const { routingProfiles } = useSettings()
    const [availableChannels, setAvailableChannels] = useState<string[]>([])
    const [concurrencyHash, setConcurrencyHash] = useState<Record<string, IMediaConcurrency>>({})
    const [routingProfileChannels, setRoutingProfileChannels] = useState<connect.ChannelType[]>([])

    const isContactMissed = Boolean(useMissedContact())

    const routingProfileName = user?.routingProfile.name

    useEffect(() => {
        if (routingProfiles) {
            if (user?.routingProfile.ID) {
                const routingProfile = getRoutingProfile(routingProfiles, user?.routingProfile.ID)
                const concurrencyHash = getMediaConcurrencyHash(routingProfile.MediaConcurrencies)
                setConcurrencyHash(concurrencyHash)
                setRoutingProfileChannels(Object.keys(concurrencyHash) as connect.ChannelType[])
            }
        } else {
            dispatch(getRoutingProfiles())
        }
    }, [user?.routingProfile.ID, routingProfiles, dispatch])

    useEffect(() => {
        if (!user || !routingProfiles || !concurrencyHash) return

        const nonRoutableStates = getNonRoutableStates(user.states)
        const isUnroutable = nonRoutableStates.includes(
            (user.showNextStatus && user.afterCallStatus?.agentStateARN) ||
                user.status.agentStateARN,
        )

        if (isUnroutable || isContactMissed) {
            setAvailableChannels([])
            return
        }

        const channelsAvailabilityHash: Record<string, boolean> = {}

        for (const channel of routingProfileChannels) {
            if (concurrencyHash[channel]) {
                channelsAvailabilityHash[channel] = true

                if (contactsSum[channel] >= concurrencyHash[channel].concurrency) {
                    if (!concurrencyHash[channel].isCrossChannel) {
                        setAvailableChannels([])
                        return
                    }

                    channelsAvailabilityHash[channel] = false
                } else if (contactsSum[channel] > 0 && !concurrencyHash[channel].isCrossChannel) {
                    setAvailableChannels([channel])
                    return
                }
            }
        }

        const availableChannelsTmp: string[] = []

        routingProfileChannels.forEach((channel) => {
            if (channelsAvailabilityHash[channel]) {
                availableChannelsTmp.push(channel)
            }
        })

        setAvailableChannels(availableChannelsTmp)
    }, [user?.states, user?.status, routingProfileChannels, isContactMissed])

    let ariaLabel = `${routingProfileName} routing profile, `
    for (const channelName of routingProfileChannels) {
        const isAvalable = availableChannels.includes(channelName)
        ariaLabel += `${channelName} channel ${isAvalable ? 'available' : 'not available'}, `
    }

    return (
        <div className={styles.card} aria-label={ariaLabel} tabIndex={0} role="menuitem">
            <div className={styles.name} title={routingProfileName}>
                {routingProfileName}
            </div>
            <div className={styles.channels}>
                {routingProfileChannels.map((channelName) => {
                    const isAvalable = availableChannels.includes(channelName)
                    const icon = channelIcons[channelName][isAvalable ? 'available' : 'unavailable']
                    const title = `${channelName} channel ${isAvalable ? 'available' : 'not available'}`

                    return <img className={styles.icon} src={icon} alt={title} title={title} />
                })}
                {routingProfiles && !routingProfileChannels?.length ? (
                    <span>Requires configuration</span>
                ) : null}
            </div>
        </div>
    )
}

export default CapacityCard
