import { useDispatch } from 'react-redux'

import { removeChatConnection } from 'store/chat/chat.actions'
import MissedContactCard from 'components/MissedContactCard'
import { IChat } from 'store/chat/chat.state'

import { ReactComponent as ChatIcon } from 'images/chat-icon.svg'

interface Props {
    chat: IChat
}

const MissedChat = ({ chat }: Props) => {
    const dispatch = useDispatch()

    const onCloseChat = () => {
        dispatch(removeChatConnection(chat.id))
    }

    const getChatState = (chatStatus: connect.ContactStateType) => {
        if (chatStatus === connect.ContactStateType.MISSED) {
            return 'Missed'
        }

        if (chatStatus === connect.ContactStateType.REJECTED || chatStatus === connect.ContactStateType.ERROR) {
            return 'Rejected'
        }
    }

    const chatState = getChatState(chat.status)

    if (!chatState) return null

    return (
        <MissedContactCard
            onClose={onCloseChat}
            ariaLabel={`Chat contact ${chatState}`}
            icon={<ChatIcon title='Chat' data-testid='chat-icon' aria-hidden={true} />}
            header={`${chatState} Chat`}
        />            
    )
}

export default MissedChat
