import React from 'react'

import { Button } from '@missionlabs/smartagent-app-components'

import { ReactComponent as CrossIconFill } from 'images/icon-cross-small.svg'

import styles from './ContactCloseButton.module.scss'

interface Props {
    onKeyPress: (evt: React.KeyboardEvent) => void
    ariaLabel?: string
    onClick?: (evt: React.MouseEvent) => void
    disabled?: boolean
}

const ContactCloseButton = ({ onKeyPress, onClick, ariaLabel = 'Close', disabled }: Props) => {
    return (
        <Button
            tabIndex={0}
            onKeyPress={onKeyPress}
            className={styles.closeButton}
            onClick={onClick}
            data-testid="contactCloseButton"
            aria-label={ariaLabel}
            disabled={disabled}
        >
            <CrossIconFill />
        </Button>
    )
}

export default ContactCloseButton
