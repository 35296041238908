import { Button } from '@missionlabs/smartagent-app-components'
import {
    GlobalStyles,
    lightTheme,
    MeetingProvider,
    RemoteVideos,
    useMeetingManager,
    useRosterState,
    useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { Loader } from 'components'
import { useEffect, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { updateContactAttributes } from 'store/contact/contact.reducer'
import { selectContact } from 'store/contact/contact.selectors'
import {
    deleteMeeting,
    forgetMeeting,
    selectMeeting,
    setMeetingStatus,
} from 'store/videoMeeting/videoMeeting.reducer'
import { VideoMeetingState } from 'store/videoMeeting/videoMeeting.state'
import styled, { ThemeProvider } from 'styled-components'
import styles from './index.module.scss'

export const VideoSession = () => {
    return (
        <div>
            <ThemeProvider theme={lightTheme}>
                <GlobalStyles />
                <MeetingProvider>
                    <VideoSessionWrapped />
                </MeetingProvider>
            </ThemeProvider>
        </div>
    )
}

const StyledVideo = styled(RemoteVideos)`
    * {
        object-fit: contain !important;
        position: relative !important;
        aspect-ratio: 16 / 9 !important;
        border-radius: 10px;
    }
`

const VideoSessionWrapped = () => {
    const dispatch = useDispatch()
    const contact = useSelector(selectContact)
    const videoMeeting = useSelector(selectMeeting)
    const meetingManager = useMeetingManager()
    const { muted, toggleMute } = useToggleLocalMute()
    const { roster } = useRosterState()
    const [fullscreen, setFullscreen] = useState(false)

    const joinMeeting = async () => {
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
            videoMeeting.meeting,
            videoMeeting.agentAttendee,
        )
        await meetingManager.join(meetingSessionConfiguration)
        await meetingManager.start()
    }

    const handleCancel = async () => {
        dispatch(deleteMeeting())
        dispatch(forgetMeeting())
    }

    const handleEnd = async () => {
        if (videoMeeting.meeting?.MeetingId) {
            dispatch(
                setMeetingStatus({
                    status: 'ENDED',
                }),
            )
            dispatch(deleteMeeting())
        }
    }

    useEffect(() => {
        joinMeeting()
    }, [])

    useEffect(() => {
        if (!muted) toggleMute()
        if (videoMeeting.status === 'IN_PROGRESS') return

        const hasCustomer = Object.values(roster).find((user) =>
            user.externalUserId?.includes('customer'),
        )
        if (!hasCustomer) return
        dispatch(
            updateContactAttributes({
                ID: contact!.ID,
                attributes: { 'sa-video-meeting-id': videoMeeting.meeting!.MeetingId },
            }),
        )
        dispatch(
            setMeetingStatus({
                status: 'IN_PROGRESS',
            }),
        )
    }, [roster])

    if (videoMeeting.status === 'IN_PROGRESS') {
        return (
            <div
                data-testid={'container-div'}
                className={fullscreen ? styles.containerFull : styles.container}
            >
                <span
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        background: '#0003',
                        borderRadius: '10px',
                        height: fullscreen ? '100%' : '50vh',
                        aspectRatio: '16 / 9',
                    }}
                >
                    <H
                        data-testid={'status-header'}
                        style={{
                            position: 'absolute',
                        }}
                    >
                        Customer Camera Disabled
                    </H>
                    <StyledVideo
                        className={fullscreen ? styles.videoContainerFull : styles.videoContainer}
                    />
                </span>
                <span
                    style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: fullscreen ? '25%' : '100%',
                    }}
                >
                    <span></span>
                    <span>
                        <Button
                            styling="white"
                            title="End Session"
                            elastic
                            onClick={handleCancel}
                            className={styles.button}
                            style={{
                                width: '130px',
                            }}
                            disabled={videoMeeting.deleteLoading}
                        >
                            Take Screenshot
                        </Button>
                        {!fullscreen && (
                            <Button
                                styling="red"
                                title="End Session"
                                style={{ width: '130px' }}
                                className={styles.button}
                                elastic
                                onClick={handleEnd}
                                disabled={videoMeeting.deleteLoading}
                            >
                                {videoMeeting.deleteLoading ? (
                                    <Loader
                                        size={16}
                                        color="#000"
                                        loaderType="clip"
                                        className="sm-pad"
                                    />
                                ) : (
                                    'End Session'
                                )}
                            </Button>
                        )}
                    </span>
                    <Button
                        styling="white"
                        title="Full Screen"
                        className={styles.button}
                        elastic
                        onClick={() => setFullscreen(!fullscreen)}
                    >
                        {fullscreen ? (
                            <span className={['xsm-mar-right', styles.closeIcon].join(' ')}></span>
                        ) : (
                            <span
                                className={['xsm-mar-right', styles.fullscreenIcon].join(' ')}
                            ></span>
                        )}
                    </Button>
                </span>
            </div>
        )
    } else {
        return (
            <div className={styles.container}>
                <span
                    style={{
                        aspectRatio: '16 / 9',
                        height: '50vh',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexDirection: 'column',
                        display: 'flex',
                    }}
                >
                    {videoMeeting.status === 'ENDED' ? (
                        <H data-testid={'status-header'}>You have ended the video share</H>
                    ) : (
                        <LoadingSession videoMeeting={videoMeeting} handleCancel={handleCancel} />
                    )}
                </span>
            </div>
        )
    }
}

const LoadingSession = (props: { videoMeeting: VideoMeetingState; handleCancel: () => {} }) => {
    return (
        <>
            <div className={styles.loader}>
                <Loader size={64} color="#000" loaderType="clip" />
            </div>
            <H data-testid={'status-header'}>
                Waiting for {props.videoMeeting.smsInviteSentTo} to join...
            </H>
            <Button
                styling="red"
                title="Cancel Session"
                style={{ width: '130px' }}
                elastic
                onClick={props.handleCancel}
                disabled={props.videoMeeting.deleteLoading}
            >
                {props.videoMeeting.deleteLoading ? (
                    <Loader size={16} color="#fff" loaderType="clip" className="sm-pad" />
                ) : (
                    'Cancel Session'
                )}
            </Button>
        </>
    )
}
