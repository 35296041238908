import React, { PropsWithChildren, useState } from 'react'

interface IContactPanelContextState {
    queuesOpen: boolean
    agentsOpen: boolean
    realtimeOpen: boolean
}

interface IContactPanelContextActions {
    setQueuesOpen(open: boolean): void
    setAgentsOpen(open: boolean): void
    setRealtimeOpen(open: boolean): void
}

export interface IContactPanelValue
    extends IContactPanelContextState,
        IContactPanelContextActions {}

export const ContactPanelContext = React.createContext<IContactPanelValue>({} as any)

export const ContactPanelProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [queuesOpen, setQueuesOpen] = useState(false)
    const [agentsOpen, setAgentsOpen] = useState(false)
    const [realtimeOpen, setRealtimeOpen] = useState(false)

    return (
        <ContactPanelContext.Provider
            value={{
                queuesOpen,
                agentsOpen,
                realtimeOpen,
                setQueuesOpen,
                setAgentsOpen,
                setRealtimeOpen,
            }}
        >
            {children}
        </ContactPanelContext.Provider>
    )
}
