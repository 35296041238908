var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, jsx } from "react/jsx-runtime";
import React, { PureComponent } from "react";
import classNames from "classnames";
import "react-player";
import "react-spinners";
import Button from "./Button.mjs";
import "react-accessible-headings";
import "./index-CVvtqm4P.mjs";
import "./Form.mjs";
import "./Input.mjs";
import "./Popup.mjs";
import "./ReactDatePicker.mjs";
import "./Tooltip.mjs";
import "./TriggeredPopup.mjs";
import "draft-js";
import "react-draft-wysiwyg";
/* empty css                       */
class Tabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.selected || 0
    };
    this.selectTab = this.selectTab.bind(this);
  }
  getHeaders() {
    if (!this.props.children)
      return null;
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    return React.Children.map(children, (child, i) => {
      return React.cloneElement(child, {
        onClick: this.selectTab,
        index: i,
        selected: i === this.state.index
      });
    });
  }
  selectTab(index) {
    this.setState({ index });
  }
  getContent() {
    let children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    if (!children)
      return null;
    children = children.filter((c) => !!c);
    const content = children[this.state.index];
    return content ? content.props.children : null;
  }
  render() {
    return /* @__PURE__ */ jsxs("div", { className: "sa-tabs", children: [
      /* @__PURE__ */ jsx("div", { className: "sa-tab-headers", children: this.getHeaders() }),
      /* @__PURE__ */ jsx("div", { className: "sa-tab-content", children: this.getContent() })
    ] });
  }
}
__publicField(Tabs, "defaultProps", {
  selected: 0
});
class Tab extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "onClick", () => {
      this.props.onClick(this.props.index);
    });
  }
  render() {
    const classes = classNames(
      {
        "sa-tab": true,
        selected: this.props.selected
      },
      this.props.className
    );
    const textColour = this.props.selected ? this.props.textColour : this.props.inactiveTextColour;
    const buttonStyle = {
      width: this.props.width,
      backgroundColor: this.props.backgroundColour,
      color: textColour,
      boxShadow: this.props.selected ? this.props.bottomLineColour ? `inset 0 -2px 0 0 ${this.props.bottomLineColour}` : void 0 : this.props.inactiveBottomLineColour ? `inset 0 -2px 0 0 ${this.props.inactiveBottomLineColour}` : void 0,
      fontSize: this.props.textSize
    };
    return /* @__PURE__ */ jsx(
      Button,
      {
        style: buttonStyle,
        className: classes,
        onClick: this.onClick,
        type: "button",
        children: this.props.title
      },
      this.props.index
    );
  }
}
__publicField(Tab, "defaultProps", {
  onClick: () => {
  },
  index: 0,
  selected: false
});
export {
  Tab,
  Tabs as default
};
