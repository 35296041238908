import { Loader, NoDataMessage } from 'components'
import useHasFeature, { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as MetricsActions from 'store/metrics/metrics.actions'
import { getRTMQueues } from 'store/settings/settings.actions'
import { getRTMQueuesFromState } from 'store/settings/settings.utils'
import RootState from 'store/state'
import { Queue } from 'store/user/user.state'

import { Box, Table } from '@missionlabs/smartagent-app-components'
import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

import allQueueColumns from './Columns/allQueueColumns'
import { getQueueColsFromConfig } from './Columns/utils'
import QueueMetricsHeader from './Header'
import { filteredQueueColumns, getLongPollingMetricData } from './utils'

export interface Props {
    isRTMPage?: boolean
}

/*
This component handles both the long polling and websocket versions for all queue metrics.
This includes the queue stats page and the realtime-metrics page.
Unfortunately all of the functionality and the associated data types for the queue stats metrics, realtime-metrics page
metrics and websocket metrics are different, due to the time constraints in getting the websocket feature out,
we haven't had time to refactor all of this functionality as much as we would like to, which is why there is so much complexity
surrounding this component.
*/
const QueueMetrics: React.FC<Props> = ({ isRTMPage = false }) => {
    const dispatch = useDispatch()
    const getFeatureConfig = useGetFeatureConfig()
    let { showQueueSummary } = getFeatureConfig(AppFeatures.REALTIME_METRICS) || {}

    showQueueSummary = isRTMPage ? showQueueSummary : false

    const userTags = useSelector((state: RootState) => state.user?.tags)

    const hasFeature = useHasFeature()
    const isRTMRedesigned = hasFeature(AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED)
    const realtimeQueueMetrics = useSelector(
        (state: RootState) => state.metrics?.realtimeQueueMetrics,
    )
    const statsQueueMetrics = useSelector((state: RootState) => state.metrics?.statsQueueMetrics)
    const filters = useSelector((state: RootState) => state.metrics?.filters)
    const { timeRange, queueIDs, timeZone } = filters

    const metricsConfig = useSelector((state: RootState) => state.metrics?.config)

    // queues is a list of all queues for realtime metrics, userQueues are the queues used to filter the stats page
    // queuesLoading is used by RTM page to determine whether there is queue data ready to show or not
    const [queues, queuesLoading] = useSelector(getRTMQueuesFromState)
    const userQueues = useSelector((state: RootState) => state.user?.queues)
    const statsQueueIds = userQueues?.map((q: Queue) => q.queueId.split('/queue/')[1])
    let cols: SATableColumn<any>[]

    if (isRTMPage) {
        cols = allQueueColumns

        if (metricsConfig) {
            cols = getQueueColsFromConfig(metricsConfig, true)
        }
    } else {
        cols = filteredQueueColumns

        if (metricsConfig) {
            cols = getQueueColsFromConfig(metricsConfig, false)
        }
    }

    const tableData = useMemo(() => {
        const metricsToUse = isRTMPage ? realtimeQueueMetrics : statsQueueMetrics
        return getLongPollingMetricData({
            queues,
            queueIDs,
            statsQueueIds,
            queueMetrics: metricsToUse,
            showQueueSummary,
            isRTMPage,
            metricsConfig,
        })
    }, [
        realtimeQueueMetrics,
        statsQueueMetrics,
        queues,
        queueIDs,
        userQueues,
        cols,
    ])

    useEffect(() => {
        if (!queues && userTags) dispatch(getRTMQueues(userTags))
        dispatch(MetricsActions.getQueueMetrics(isRTMPage))
    }, [dispatch, queues, timeRange, timeZone, queueIDs, userTags])

    useInterval(
        () => {
            if (userTags) dispatch(getRTMQueues(userTags))
        },
        60 * 60 * 1000,
    ) // refresh queues hourly

    // Set up long polling
    const queuesRefreshInterval = isRTMRedesigned ? 5000 : 60000
    useInterval(() => {
        dispatch(MetricsActions.getQueueMetrics(isRTMPage))
    }, queuesRefreshInterval)

    // New generic header component for all three instances
    const header = (
        <QueueMetricsHeader
            queues={queues}
            filters={filters}
            onFilterChange={(filters) => dispatch(MetricsActions.setMetricFilters(filters))}
            isRTMPage={isRTMPage}
            userQueues={userQueues}
        />
    )

    return (
        <Box
            className={isRTMPage ? 'realtime-metrics-panel' : ''}
            collapse
            header={header}
            boxLabel="Queues"
            alt
        >
            {(isRTMPage &&
                !queuesLoading &&
                realtimeQueueMetrics) ||
            (!isRTMPage && statsQueueMetrics) ? (
                <Table
                    sort="queueName"
                    selectable
                    cols={cols}
                    data={tableData && cols.length ? tableData : []}
                    caption="Real time queue metrics"
                    noData={
                        <NoDataMessage text={!cols.length ? 'No metrics configured' : undefined} />
                    }
                />
            ) : (
                <Loader />
            )}
        </Box>
    )
}

export default QueueMetrics
