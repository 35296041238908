import { Button, Dropdown, TimeElapsed } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch'
import ExitFullScreenIcon from '../../images/icon-exit-fullscreen.svg'
import FullScreenIcon from '../../images/icon-fullscreen.svg'
import ZoomInIcon from '../../images/icon-zoom-in.svg'
import ZoomOutIcon from '../../images/icon-zoom-out.svg'
import ZoomResetIcon from '../../images/icon-zoom-reset.svg'
import PauseIcon from './Pause-noFill.svg'
import PlayIcon from './Play-noFill.svg'
import { PlaybackRateOption } from './interfaces/AudioPlayer'
import './video-controls.scss'

interface Props {
    audio: React.RefObject<HTMLAudioElement> | null
    containerRef: React.MutableRefObject<HTMLDivElement | null> | null
    elapsed?: number
    setElapsed: React.Dispatch<React.SetStateAction<number>>
    total: number
    isPlaying: boolean
    playPause(): void
    totalDuration: number
    videoRef: React.MutableRefObject<HTMLVideoElement | null> | null
    videoZoomControls: ReactZoomPanPinchHandlers | null
    playback: {
        playbackRateOptions: PlaybackRateOption[]
        playbackRateOnChange: (option: any) => void
        currentPlaybackOption: PlaybackRateOption
    }
    fullScreen: {
        isFullScreen: boolean
        toggleFullScreen: React.Dispatch<React.SetStateAction<boolean>>
    }
    setPersistentElapsed: React.Dispatch<React.SetStateAction<number | null>>
}

interface ControlType {
    icon: string
    dataTestId: string
    alt: string
    title: string
    onClick: () => void
    disabled?: boolean
}

export const VideoControls: React.FC<Props> = ({
    audio,
    containerRef,
    elapsed,
    total,
    isPlaying,
    totalDuration,
    videoRef,
    setElapsed,
    videoZoomControls,
    playback,
    fullScreen,
    setPersistentElapsed,
    ...actions
}) => {
    const { isFullScreen, toggleFullScreen } = fullScreen

    const handleFullScreen = () => {
        toggleFullScreen(!isFullScreen)
        if (elapsed) {
            setPersistentElapsed(elapsed)
        }
    }

    if (!videoZoomControls) return null

    const controls: ControlType[] = [
        {
            icon: ZoomInIcon,
            dataTestId: 'zoom-in-button',
            title: 'Zoom In',
            alt: 'Zoom In',
            onClick: () => videoZoomControls.zoomIn(),
        },
        {
            icon: ZoomOutIcon,
            dataTestId: 'zoom-out-button',
            title: 'Zoom Out',
            alt: 'Zoom Out',
            onClick: () => videoZoomControls.zoomOut(),
        },
        {
            icon: ZoomResetIcon,
            dataTestId: 'zoom-reset-button',
            title: 'Zoom Reset',
            alt: 'Zoom Reset',
            onClick: () => videoZoomControls.resetTransform(),
        },

        {
            icon: isFullScreen ? ExitFullScreenIcon : FullScreenIcon,
            dataTestId: 'fullscreen-button',
            title: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            alt: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            onClick: handleFullScreen,
        },
    ]

    return (
        <div
            className="sa-videoplayer-controls-wrapper"
            data-testid="screen-recording-video-controls"
        >
            <div className="sa-videoplayer-controls">
                <div className="timeelapsed-wrapper">
                    <TimeElapsed
                        ariaLive="off"
                        hours
                        format="hh:mm:ss"
                        noTick
                        date={TimeElapsed.from(elapsed ?? 0)}
                    />
                    <span>{' / '}</span>
                    <TimeElapsed
                        ariaLive="off"
                        hours
                        format="hh:mm:ss"
                        noTick
                        date={TimeElapsed.from(total)}
                    />
                </div>

                <Button style={{ margin: '0px 3px' }} onClick={actions.playPause}>
                    <img
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt={isPlaying ? 'Pause' : 'Play'}
                        title={isPlaying ? 'Pause' : 'Play'}
                    />
                </Button>

                <Dropdown
                    options={playback.playbackRateOptions}
                    onChange={playback.playbackRateOnChange}
                    value={playback.currentPlaybackOption?.label || ''}
                />

                {controls.map(({ icon, alt, title, onClick, disabled, dataTestId }, index) => {
                    return (
                        <Button
                            data-testid={dataTestId}
                            key={`${index}-${icon}`}
                            disabled={disabled}
                            onClick={onClick}
                        >
                            <img src={icon} alt={alt} title={title} />
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}
