import { Meeting } from '@aws-sdk/client-connect'
import { Attendee } from 'amazon-chime-sdk-js'
import { AxiosResponse } from 'axios'
import { makeRequest } from '.'

export interface MeetingAPIBaseRequest {
    companyID: string
    instanceID: string
    token: string
}

export interface CreateMeetingAPIRequest extends MeetingAPIBaseRequest {
    contactID: string
}

export interface SendMeetingInviteAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
    customerEndpointAddress: string
    brand: string
}

export interface DeleteMeetingAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
}

export interface UpdateMeetingStatusAPIRequest extends MeetingAPIBaseRequest {
    meetingID: string
    status: string
}

export interface CreateMeetingAPIResponse {
    meeting: Meeting
    agentAttendee: Attendee
}

export interface MeetingErrorAPIResponse {
    statusCode: number
    message: string
}

const getBaseURL = ({ companyID, instanceID }: Omit<MeetingAPIBaseRequest, 'token'>) => {
    return `/video-meeting-service/companies/${companyID}/instances/${instanceID}`
}

export const createMeeting = async ({
    contactID,
    token,
    ...baseRequest
}: CreateMeetingAPIRequest): Promise<CreateMeetingAPIResponse> => {
    const response = await makeRequest<CreateMeetingAPIResponse>({
        url: `${getBaseURL(baseRequest)}/meeting/create`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { contactID },
    })
    return response.data
}

export const sendMeetingInvite = async ({
    meetingID,
    customerEndpointAddress,
    brand,
    token,
    ...baseRequest
}: SendMeetingInviteAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/invite/sms`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { meetingID, customerEndpointAddress, brand },
    })
    return response
}

export const deleteMeeting = async ({
    meetingID,
    token,
    ...baseRequest
}: DeleteMeetingAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'delete',
    })
    return response
}

export const updateMeetingStatus = async ({
    meetingID,
    status,
    token,
    ...baseRequest
}: UpdateMeetingStatusAPIRequest): Promise<AxiosResponse<void, any>> => {
    const response = await makeRequest<void>({
        url: `${getBaseURL(baseRequest)}/meeting/${meetingID}`,
        headers: { 'X-Amz-Security-Token': token },
        method: 'put',
        data: { status },
    })
    return response
}
