export const attributeDictionaryToRecord= (attributes: connect.AttributeDictionary) => {
    return Object.keys(attributes).reduce((acc: Record<string, string>,key) => {
        acc[key] = attributes[key].value;
        return acc
    }, {})
}

export const filterAttributes = (attributes?: connect.AttributeDictionary) => {

    if (!attributes) return undefined;

    const records = attributeDictionaryToRecord(attributes)

    const keyNames = [
        // Used for Tuiglobal, for sending C4C events
        'sa-screenpop-key',
        'sa-screenpop-value',
    ];

    return Object.keys(records)
        .filter((att) => keyNames.includes(att))
        .reduce((acc: Record<string, string>, key) => {
            acc[key] = attributes[key].value;
            return acc
        }, {});

}