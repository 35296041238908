import { useDispatch } from 'react-redux'

import { removeTaskConnection } from 'store/tasks/tasks.actions'
import MissedContactCard from 'components/MissedContactCard'
import { ITask } from 'store/tasks/tasks.state'

import { ReactComponent as TaskIcon } from 'images/task-icon.svg'

interface Props {
    task: ITask
}

const MissedTask = ({ task }: Props) => {
    const dispatch = useDispatch()

    const onCloseTask = () => {
        dispatch(removeTaskConnection(task.id))
    }

    const getTaskState = (taskStatus: connect.ContactStateType) => {
        if (taskStatus === connect.ContactStateType.MISSED) {
            return 'Missed'
        }

        if (taskStatus === connect.ContactStateType.REJECTED || taskStatus === connect.ContactStateType.ERROR) {
            return 'Rejected'
        }
    }

    const taskState = getTaskState(task.status)

    if (!taskState) return null

    return (
        <MissedContactCard
            onClose={onCloseTask}
            ariaLabel={`Task contact ${taskState}`}
            header={`${taskState} Task`}
            icon={<TaskIcon title='Task' data-testid='task-icon' aria-hidden={true} />}
        />
    )
}

export default MissedTask
