import './contact-log.scss'

import { CONTACT_LOG } from 'constants/index'
import useApp from 'hooks/redux/useApp'
import useContact from 'hooks/redux/useContact'
import useMonitoring from 'hooks/redux/useMonitoring'
import useContactLogForm from 'hooks/useContactLogForm'
import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateContactAttributes as updateContactAttributesApiCall } from 'services/api/api.contact'
import { FormField, Option } from 'store/app/app.state'
import { updateNote } from 'store/chat/chat.actions'
import { updateACWAttributes, updateContactAttributes } from 'store/contact/contact.actions'
import { ICallContactAttributes } from 'store/contact/contact.state'
import { contactAttributes } from 'store/contact/contact.utils'
import { updateACWAttributesQueuedTasks } from 'store/queuedTasks/queuedTasks.actions'
import RootState from 'store/state'
import { isContactLogEmpty } from 'utils'

import { Box, Input } from '@missionlabs/smartagent-app-components'
import { H, Level } from 'react-accessible-headings'

import { InputTextArea } from '@missionlabs/smartagent-app-components/dist/Input/index'
import { useCompanyHasFeature } from 'hooks/useHasFeature'
import { ReactComponent as ACWPredictIcon } from 'images/icon-auto-reply.svg'
import { AppFeatures } from 'store/app/app.features'
import { EContactLogFieldType } from 'views/AdminSettings/ContactLogs/types'
import Plugins from '../Plugins'
import ContactLogFormBuilder from './ContactLogFormBuilder/'
import CompleteWrapUp from './complete-wrap-up'

interface Props extends ReturnType<typeof mapStateToProps> {
    hideHeader?: boolean
    acw?: boolean
    onSubmit: () => void
    channel: string
    queuedTaskContactIDs?: string[]
    contactLogGroup?: string
}
interface DefaultValues {
    [key: string]: string | string[]
}
export interface DefaultData extends DefaultValues {}

const ContactLog: React.FC<Props> = ({
    hideHeader,
    acw,
    onSubmit,
    channel,
    contacts,
    queuedTaskContactIDs,
    contactLogGroup,
}) => {
    const { plugins, appConfig } = useApp()
    const dispatch = useDispatch()
    const contactLog = useContactLogForm(contactLogGroup)
    const { isMonitoringOrBarging } = useMonitoring()
    const companyHasFeature = useCompanyHasFeature()
    const showAcwPredictionEnabled = companyHasFeature(AppFeatures.SHOW_ACW_PREDICTION) // Temporary feature flag that allows for showing predicted acw values when available
    const textAreaInput = useRef<InputTextArea>(null)
    const defaultACWAttributesRef = useRef<Record<string, string> | null>()

    let contact = useContact()
    if (!contact?.ID && contacts.length) {
        contact = contacts.filter((contact) => contact.channel === 'VOICE')[0]
    }

    const app = useSelector<RootState, RootState['app']>(({ app }) => app)
    const auth = useSelector<RootState, RootState['auth']>(({ auth }) => auth)
    const attributes: ICallContactAttributes | undefined = contact?.attributes
    const attributeRef = useRef<string[]>([])
    const [notes, setNotes] = useState('')
    const hasPlugins = plugins.find(
        (plugin) =>
            plugin.type === CONTACT_LOG && (plugin.channel ? plugin.channel === channel : true),
    )
    const hideCallNotes = appConfig.hideCallNotes

    const onUpdateNotes = () => {
        if (notes === contact?.attributes?.['sa-notes']) return
        if (channel === 'CHAT') dispatch(updateNote({ id: contact?.ID!, note: notes }))
        updateContact(notes)
    }

    const updateContact = async (note: string) => {
        const attributes = {
            'sa-acw-notes': note,
            'sa-notes': note,
        }
        if (Boolean(queuedTaskContactIDs)) {
            try {
                for (const contactID of queuedTaskContactIDs!) {
                    await updateContactAttributesApiCall(
                        app.ID,
                        app.instance!.ID,
                        contactID,
                        auth.token!,
                        attributes,
                    )
                }
            } catch (error) {
                console.error(`Error during updating updating contact attributes: [${error}]`)
            }
            return
        }
        // Two fields for backward compatability
        dispatch(updateContactAttributes(contact?.ID!, attributes))
    }

    const onNotesChange = (notes: any) => {
        setNotes(notes)
    }

    const onACWChange = (name: string, value: any) => {
        const attributeName = name && name.indexOf('sa-') === 0 ? name : `sa-${name}`
        const propertyName =
            contactAttributes.indexOf(attributeName) > -1 ? attributeName : `sa-acw-${name}`
        propertyName === 'sa-acw-Fascia' &&
            dispatch(updateContactAttributes(contact?.ID!, { 'sa-acw-Fascia': value }))

        // Special case for TUI schedule
        if (name === 'Schedule Date') {
            value = new Date(value).toLocaleDateString('en-GB')
        }

        if (queuedTaskContactIDs?.length) {
            dispatch(
                updateACWAttributesQueuedTasks(queuedTaskContactIDs, {
                    [propertyName]: value,
                }),
            )
            return
        }
        dispatch(
            updateACWAttributes(contact?.ID!, {
                ...contact?.acwAttributes,
                [propertyName]: value,
            }),
        )
        // dont duplicate an attribute
        if (!attributeRef.current.find((attribute) => attribute === name))
            attributeRef.current = [...attributeRef.current, name]
    }

    const submitContactLog = () => {
        const attributes = contact?.attributes
        const acwAttributes = contact?.acwAttributes

        if (!acwAttributes || !attributes) {
            onSubmit()
            return
        }

        let predictedACWScore: number | undefined
        const acwPredictionScoreAttributeKey = 'sa-acw-prediction-score'
        if (showAcwPredictionEnabled) {
            const predictedAcwAttributes = Object.keys(attributes).filter(
                (key) =>
                    key.startsWith('sa-acw') &&
                    key.endsWith('-predicted') &&
                    key.replace('-predicted', '') in acwAttributes,
            )
            if (predictedAcwAttributes?.length) {
                const correctPredictions = predictedAcwAttributes.reduce((count: number, key) => {
                    const nonPredictedKey = key.replace('-predicted', '')
                    if (acwAttributes[nonPredictedKey] === attributes[key]) return count + 1
                    return count
                }, 0)

                predictedACWScore = (correctPredictions / predictedAcwAttributes.length) * 100
                onACWChange(acwPredictionScoreAttributeKey, predictedACWScore)
            }
        }

        dispatch(
            updateACWAttributes(contact?.ID!, {
                ...contact?.acwAttributes,
                ...(showAcwPredictionEnabled &&
                    predictedACWScore !== undefined && {
                        [acwPredictionScoreAttributeKey]: predictedACWScore,
                    }),
            }),
        )

        onSubmit()
    }

    const renderSubmit = () => {
        return <CompleteWrapUp onComplete={submitContactLog} />
    }

    const noSubmit = () => {
        return <span />
    }

    useEffect(() => {
        const attributes = contact?.attributes

        if (!attributes) return

        const acwAttributeKeys = Object.keys(attributes).filter(
            (key) => key.startsWith('sa-acw') && !key.endsWith('-predicted'),
        )

        const updatedAcwAttributes = acwAttributeKeys.reduce(
            (acwAttributes: Record<string, string>, key) => {
                if (acwAttributes?.[key] !== undefined) return acwAttributes
                acwAttributes[key] = attributes[key]
                return acwAttributes
            },
            {},
        )

        if (Object.keys(updatedAcwAttributes).length === 0) return
        dispatch(
            updateACWAttributes(contact?.ID!, {
                ...(showAcwPredictionEnabled ? defaultACWAttributesRef.current : {}),
                ...contact?.acwAttributes,
                ...updatedAcwAttributes,
            }),
        )
    }, [contact?.attributes])

    useEffect(() => {
        // Don't persist notes between monitored contact logs
        isMonitoringOrBarging ? setNotes('') : setNotes(attributes?.['sa-acw-notes'])
    }, [contact?.ID, isMonitoringOrBarging])

    const getOption: any = (option: Option | string) => {
        if (typeof option === 'string' || !option?.children) return [option]
        return [option, ...option.children.flatMap(getOption)]
    }

    // Recursively get all of the options for a given field
    const getAllOptions = (field: FormField): (Option | string)[] => {
        const options: (Option | string)[] = []
        const fieldOptions = field.options
        fieldOptions?.forEach((option: Option) => options.push(getOption(option)))
        return options.flatMap((option) => option)
    }
    const contactLogHasPredictedValues = useMemo(() => {
        if (!contactLog.form?.fields || !attributes) return false

        return contactLog.form.fields.some(
            (field: FormField) =>
                `sa-acw-${field.name}-predicted` in attributes &&
                attributes[`sa-acw-${field.name}-predicted`] !== '',
        )
    }, [contactLog.form?.fields, attributes])

    const defaultValues: DefaultValues = useMemo(() => {
        if (isMonitoringOrBarging) return {}

        const defaultValuesArray = contactLog.form?.fields?.map((field: FormField) => {
            const attributeValue =
                attributes?.[`sa-acw-${field.name}`] ??
                (showAcwPredictionEnabled
                    ? attributes?.[`sa-acw-${field.name}-predicted`]
                    : undefined)

            const fieldOptions = getAllOptions(field)
            let fieldLabel: string | string[] | undefined

            if (field.type === EContactLogFieldType.checkbox) {
                // Checkbox field takes in an array of strings
                const selectedFieldOptions: string[] = []
                fieldOptions?.forEach((option) => {
                    if (attributeValue?.includes(option))
                        selectedFieldOptions.push(option as string)
                })
                fieldLabel = selectedFieldOptions
            } else if (field.type === EContactLogFieldType.text) {
                fieldLabel = attributeValue
            } else {
                // Covers populating radio fields, dropdown fields and hierachy fields
                const selectedFieldOption = fieldOptions?.find((option) => {
                    const escapedAttributeValue = attributeValue?.replaceAll('\\\\', '\\')

                    // Check as option can either be a string or an Option here
                    return typeof option === 'string'
                        ? option === escapedAttributeValue
                        : option.data === escapedAttributeValue
                })

                fieldLabel =
                    typeof selectedFieldOption === 'string'
                        ? selectedFieldOption
                        : selectedFieldOption?.label
            }

            return { [field.name]: fieldLabel ?? '' }
        })

        // Transforms defaultValuesArray into correct format expected by the ContactLogFormBuilder
        // Example => [{someKey: someValue}, {anotherKey: anotherValue}] -> {someKey: someValue, anotherKey: anotherValue}
        const defaultValues: DefaultValues = {}
        defaultValuesArray?.forEach((value: DefaultValues) => {
            defaultValues[Object.keys(value)[0]] = Object.values(value)[0]
        })

        return defaultValues
    }, [contactLog])

    useEffect(() => {
        if (showAcwPredictionEnabled) {
            const defaultACWAttributesWithPredictedValues = Object.fromEntries(
                Object.entries(defaultValues).map(([key]) => [
                    `sa-acw-${key}`,
                    attributes?.[`sa-acw-${key}`] ?? attributes?.[`sa-acw-${key}-predicted`],
                ]),
            ) as Record<string, string>
            defaultACWAttributesRef.current = defaultACWAttributesWithPredictedValues

            /*  SMAR-11570
                I'm not sure what the intention was in passing defaultValues to updateACWAttributes previously as the keys are missing 'sa-acw',
                so I don't see how they ever worked when passed to updateACWAttributes to be used in later checks as we expect acw attribute keys to start with sa-acw.
                I'm only spreading the original defaultValues to avoid changing existing behaviour.
            */

            dispatch(
                updateACWAttributes(contact?.ID!, {
                    ...defaultValues,
                    ...defaultACWAttributesWithPredictedValues,
                }),
            )
        } else {
            dispatch(updateACWAttributes(contact?.ID!, defaultValues))
        }
    }, [showAcwPredictionEnabled])

    const noContactLogOrEmpty = isContactLogEmpty(contactLog)

    const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        // the ignore rule after due to incorrect ref forwarding in the Input component
        //@ts-ignore
        const input = textAreaInput.current?.getRef() as InputTextArea

        if (event.altKey && input) {
            event.preventDefault() // Prevent scrolling

            switch (event.key) {
                case 'ArrowUp': {
                    input.style.height = input.offsetHeight + 10 + 'px'

                    break
                }
                case 'ArrowDown':
                    input.style.height = input.offsetHeight - 10 + 'px'

                    break
                case 'ArrowRight':
                    input.style.width = input.offsetWidth + 10 + 'px'

                    break
                case 'ArrowLeft':
                    input.style.width = input.offsetWidth - 10 + 'px'

                    break
            }
        }
    }

    if (!hasPlugins && hideCallNotes && noContactLogOrEmpty) return null

    return (
        <Box header={hideHeader ? null : <H>Contact log</H>} className="contact-log-box">
            <Level>
                <section className="contact-log" aria-label="contact log">
                    {showAcwPredictionEnabled && contactLogHasPredictedValues && (
                        <div className="acw-prediction-info">
                            <ACWPredictIcon />
                            <p>
                                This Contact Log has been pre-filled with AI-generated suggestions.
                            </p>
                        </div>
                    )}
                    {hideCallNotes ? null : (
                        <div className="sm-mar-bottom">
                            <Input
                                ref={textAreaInput}
                                aria-label="Call notes input"
                                onBlur={onUpdateNotes}
                                placeholder="Call notes"
                                rows={5}
                                value={notes || ''}
                                onChange={onNotesChange}
                                onKeyDown={handleOnKeyDown}
                                type="text"
                                textarea
                            />
                        </div>
                    )}
                    {hasPlugins && (
                        <div className="md-mar-top">
                            <Plugins channel={channel} type="contact-log" />
                        </div>
                    )}
                    {noContactLogOrEmpty ? (
                        <div className="contact-log-error-message">
                            Sorry, no contact log is available
                            <br />
                            for this contact.
                        </div>
                    ) : (
                        <ContactLogFormBuilder
                            renderSubmit={acw ? renderSubmit : noSubmit}
                            data={contactLog.form as any}
                            onSubmit={onSubmit}
                            onChange={onACWChange}
                            preventDefault
                            defaultValues={defaultValues}
                            contactID={contact?.ID ?? ''}
                        />
                    )}
                </section>
            </Level>
        </Box>
    )
}

function mapStateToProps(state: RootState) {
    const { contacts } = state
    return {
        contacts,
    }
}

export default connect(mapStateToProps, {})(ContactLog)
