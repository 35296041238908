import { Meeting } from '@aws-sdk/client-connect'
import { Attendee } from 'amazon-chime-sdk-js'

export type VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus
    view: MeetingViews
    meeting: Meeting | null
    agentAttendee: Attendee | null
    contactID: string | null
    smsInviteSentTo: string | null
    inviteLoading: boolean
    deleteLoading: boolean
    status: string
}

export enum MeetingViews {
    CREATE = 'create',
    INVITE = 'invite',
    VIDEO = 'video',
}

export enum VideoMeetingLoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateMeetingAction {
    contactID: string
}

export interface SetMeetingInvitedSMSAction {
    brand: string
    smsInviteSentTo: string
    meetingID: string
}

export interface SetMeetingReqLoadingAction {
    inviteLoading?: boolean
    deleteLoading?: boolean
}

export interface SetMeetingNavigateAction {
    view: MeetingViews
}

export interface CreateMeetingSuccessAction {
    agentAttendee: Attendee
    meeting: Meeting
}

export interface SetMeetingStatus {
    status: string
}

export interface CreateMeetingFailedAction {
    message?: string
    header?: string
}
