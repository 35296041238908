const generateWidgetTitle = (
    screenRecordingURL: string | undefined,
    widgetTitle: string | undefined,
) => {
    if (screenRecordingURL) {
        return 'Screen & Call Recording'
    } else {
        return widgetTitle ?? 'Call Recording'
    }
}

export { generateWidgetTitle }
