import { formatDateTime } from '@missionlabs/smartagent-app-components'
import axios from 'axios'
import DownloadIcon from 'images/icon-download.svg'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'

interface DownloadButtonProps {
    canDownload: boolean
    contactID: string | undefined
    recordingDate: number | undefined
    signedUrl: string
}

interface DateTimeOptions {
    date?: boolean
    time?: boolean
}

const DownloadButton = ({
    canDownload,
    contactID,
    signedUrl,
    recordingDate,
}: DownloadButtonProps) => {
    const [downloadingAudioFile, setDownloadingAudioStatus] = useState(false)
    const [downloadProgressAmount, setDownloadProgressAmount] = useState(0)

    const formatDateTimeWithUnderscore = (
        date: number | Date,
        options?: DateTimeOptions,
    ): string => {
        return formatDateTime(date, options).replace(' ', '_')
    }

    const resetDownloadProgress = () => {
        setDownloadingAudioStatus(false)
        setDownloadProgressAmount(0)
    }

    const handleAudioDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        if (downloadingAudioFile) return

        try {
            setDownloadingAudioStatus(true)
            let progressAmount = 0
            // download the audio file as a blob from the signedUrl
            const audioFileResponse = await axios.get(signedUrl, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent: { loaded: number; total: number }) => {
                    const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                    // only update state every time progress has increased by 1% to stop unnecessary
                    if (percent >= progressAmount + 1) {
                        progressAmount = percent
                        setDownloadProgressAmount(percent)
                    }
                },
            })

            // create a more readable filename for the recording with the contact id and date
            const dateString = recordingDate
                ? formatDateTimeWithUnderscore(recordingDate, { date: true, time: true })
                : ''
            const fileName = `contact_${contactID}__time_${dateString}`

            // get the browser to download the file for the user with the new filename
            const url = window.URL.createObjectURL(new Blob([audioFileResponse.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.wav`)
            link.click()

            resetDownloadProgress()
        } catch (err) {
            console.log(err)
            window.open(signedUrl, '_blank')
            resetDownloadProgress()
        }
    }
    return (
        <>
            {recordingDate && contactID && canDownload ? (
                <a
                    className={`sa-audio-download lg-pad-right row middle ${downloadingAudioFile ? 'is-downloading' : ''}`}
                    href={signedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="audio-controls-download-button"
                    download
                    onClick={(e) => handleAudioDownload(e)}
                >
                    {downloadingAudioFile ? (
                        <>
                            <span className="sa-audio-download-spinner">
                                <ClipLoader color="#fff" size={15} />
                            </span>
                            <span className="sa-audio-downloading-text">
                                {downloadProgressAmount}%
                            </span>
                        </>
                    ) : (
                        <>
                            <img src={DownloadIcon} alt="Download" title="Download" />
                            Download
                        </>
                    )}
                </a>
            ) : null}
        </>
    )
}

export default DownloadButton
