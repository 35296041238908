import styles from './UnreadMessagesIndicator.module.scss'

interface Props {
    unreadMessages: number
}

const UnreadMessagesIndicator = ({ unreadMessages }: Props) => (
    <div className={styles.wrapper}>
        <span aria-hidden={true} data-testid="unreadMessagesIndicatorText">{unreadMessages}</span>
        <span className='screen-reader-only'>{`${unreadMessages} new ${unreadMessages > 1 ? "messages" : "message"} from chat`}</span>
    </div>
)

export default UnreadMessagesIndicator
