import type { ThunkDispatch } from '@reduxjs/toolkit'
import PhoneLib from 'google-libphonenumber'
import { AppFeatures, IFeature } from 'store/app/app.features'
import { getNonYouConnections } from 'store/call/call.utils'
import RootState from 'store/state'
import { WebSocketCompanionActions } from 'store/websocketCompanion/websocketCompanion.state'
import { formatPhoneNumber } from 'utils'

import { constructTags, getIdFromARN } from '../../utils'
import ContactState from '../contact/contact.state'
import * as CallActions from './call.reducer'
import { CallDirection, ConnectionId } from './call.state'

export const {
    acceptCall,
    callEnded,
    callStarted,
    completeCallACW,
    conferenceConnections,
    connectionConnecting,
    connectionStarted,
    endCall,
    endConnection,
    hold,
    initiateLegacyMonitoring,
    leaveCall,
    mute,
    recoverCall,
    rejectCall,
    resume,
    setSelectedCall,
    swapConnections,
    toggleConnections,
    unmute,
    clearCall,
} = CallActions

export function startParkCall() {
    return (
        dispatch: ThunkDispatch<RootState, undefined, CallActions.CallAction>,
        getState: () => RootState,
    ) => {
        try {
            const state = getState()

            const { config } = (state.app.features.find((f) => f.ID === AppFeatures.CALL_PARKING) ??
                {}) as IFeature<AppFeatures.CALL_PARKING>

            if (!config) {
                throw new Error('Call parking config not found')
            }

            const connect = state.user?.connects?.find(
                (c) => getIdFromARN(c.endpointARN) === config?.quickConnectID,
            )

            if (!connect) {
                throw new Error('Call parking quick connect not found')
            }

            dispatch(CallActions.parkCallContactTransfer())
            dispatch(addConnection(connect.phoneNumber, connect.name, connect))
        } catch (err) {
            console.log(err.message)
        }
    }
}

export function incomingCall(connectionId: string, number: string, contact: ContactState) {
    return (
        dispatch: ThunkDispatch<RootState, undefined, CallActions.CallAction>,
        getState: () => RootState,
    ) => {
        const { websocketCompanion, directory } = getState()
        const direction: CallDirection = 'inbound'

        const formattedPhoneNumber = formatPhoneNumber(number, PhoneLib.PhoneNumberFormat.E164)
        const contactFromDirectory =
            directory.restructuredDirectoryContacts?.contactsWithKeyAsPhoneNumber?.[
                formattedPhoneNumber
            ]
        const nameFromDirectory =
            contactFromDirectory &&
            `${contactFromDirectory?.firstName} ${contactFromDirectory?.lastName}`

        websocketCompanion.client?.sendMessage(WebSocketCompanionActions.INCOMING_CALL, {
            name: contact.customer?.name ?? nameFromDirectory ?? null,
            number,
            connectionId,
            direction,
            tags: constructTags(contact.attributes),
        })

        dispatch(
            CallActions.incomingCall({
                id: connectionId,
                number,
                contact,
                direction,
            }),
        )
    }
}

export function callbackCall(connectionId: string, number: string, contact: ContactState) {
    return CallActions.callbackCall({ id: connectionId, number, contact })
}

export function outboundCall(connectionId: string, number: string, contact: ContactState) {
    return CallActions.outboundCall({ id: connectionId, number, contact })
}

export function makeCall(number: string, name?: string, endpoint?: any, queueArn?: string) {
    return CallActions.makeCall({
        id: String(Math.random()),
        number: number?.replace(/\s/g, '') ?? '',
        name,
        endpoint,
        queueArn,
    })
}

export function updateCallStatus(connectionId: string, status: string) {
    return CallActions.updateCallStatus({ status, connectionId })
}

export function transfer(number: string, name?: string, endpoint?: any) {
    return CallActions.transferCall({
        id: String(Math.random()),
        number,
        name,
        endpoint,
    })
}

export function setHold(connectionId: ConnectionId, hold: boolean) {
    return CallActions.setHold({ connectionId, hold })
}

export function missedCall() {
    return (
        dispatch: ThunkDispatch<RootState, undefined, CallActions.CallAction>,
        getState: () => RootState,
    ) => {
        const { websocketCompanion } = getState()

        websocketCompanion.client?.sendMessage(WebSocketCompanionActions.MISSED_CALL)

        dispatch(CallActions.missedCall())
    }
}

export function addConnection(number: string, name?: string, endpoint?: any) {
    return CallActions.addConnection({
        id: String(Math.random()),
        number,
        name,
        endpoint,
    })
}

export function sendDTMF(connectionId: string, digit: string) {
    return CallActions.sendDTMF({ connectionId, digit })
}

export function updateConnections(connections: connect.VoiceConnection[]) {
    const nonYouConnections = getNonYouConnections(connections)

    return CallActions.updateConnections({ connections: nonYouConnections })
}

