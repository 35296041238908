import classNames from 'classnames'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { ContactName } from 'components'

import ContactCloseButton from 'components/MissedContactCard/ContactCloseButton'

import styles from './MissedContactCard.module.scss'

interface Props extends PropsWithChildren {
    onClose: () => void
    className?: string
    ariaLabel?: string
    icon: React.ReactNode
    header: string
}

const MissedContactCard = ({
    onClose,
    className = '',
    ariaLabel='',
    icon,
    header,
}: Props) => {
    const elemForScreenReaderRef = useRef<HTMLSpanElement>(null)
    const missedCardRef = useRef<HTMLDivElement>(null)
    const [isDisabled, setIsDisabled] = useState(false)

    const missedCardClasses = classNames(className, styles.missedContact)

    useEffect(() => {
        if (elemForScreenReaderRef?.current) {
            elemForScreenReaderRef.current.innerText = ariaLabel
        }
    }, [ariaLabel])

    useEffect(() => {
        if (missedCardRef.current) {
            missedCardRef.current.focus()
        }
    }, [])
    
    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && handleClickClose()
    }

    const handleClickClose = () => {
        setIsDisabled(true)

        if (elemForScreenReaderRef?.current) {
            elemForScreenReaderRef.current.innerText = 'Dismissed';
        }

        // Delay added to let screen reader announce "Dismissed" after clicking. 
        // IncomingContact has "alert" role, which interrupts this announcement if not delayed.
        setTimeout(() => {
            setIsDisabled(false)
            onClose()
        }, 700)
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div tabIndex={0} ref={missedCardRef} className={missedCardClasses} data-testid='missed-contact'>
            <span ref={elemForScreenReaderRef} role='status' className='only-visible-to-screen-readers'>{ariaLabel}</span>

            <div className={styles.body} aria-hidden={true}>
                {icon}

                <ContactName className={styles.header} name={header} />
            </div>

            <ContactCloseButton disabled={isDisabled} onClick={handleClickClose} onKeyPress={handleKeyPress} ariaLabel='Dismiss' />
        </div>
    )
}

export default MissedContactCard
